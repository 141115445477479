import snsWebSdk from "@sumsub/websdk";
import Cookies from "js-cookie";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { fetchUser, getVerificationToken } from "../../actions";
import requireAuth from "../../components/hocs/requireAuth";
import UserHeaderNew from "../../components/users/UserHeaderNew";
import UserSidebarNew from "../../components/users/UserSidebarNew";

class VerificationPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Верификация - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Верификация - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  async componentDidMount() {
    this.props.fetchUser();
    if (this.props.user) {
      let tokenQuery = await this.props.getVerificationToken('new-kyc-level');
      this.launchWebSdk(
        tokenQuery.token,
        this.props.user.email,
        this.props.user.phone
      );
    }
  }

  launchWebSdk(accessToken, applicantEmail, applicantPhone) {
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, async (newAccessTokenCallback) => {
        let tokenQuery = await this.props.getVerificationToken('new-kyc-level');
        newAccessTokenCallback(tokenQuery.token);
      })
      .withConf({
        lang: Cookies.get("languageCode") || 'en',
        email: applicantEmail,
        phone: applicantPhone
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on('idCheck.stepCompleted', (payload) => {
        console.log('stepCompleted', payload)
      })
      .on('idCheck.onError', (error) => {
        console.log('onError', error)
      })
      .onMessage((type, payload) => {
        console.log("WebSDK onMessage", type, payload);
      })
      .build();

    snsWebSdkInstance.launch("#sumsub-websdk-container");
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            {this.head()}
            <UserSidebarNew />
            <div className="">
              <div className="profile-page-new-wrapper" style={{borderRadius: "20px"}}>
                <div className="profile-page-box">
                  <div className="row">
                    <UserHeaderNew />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div id="sumsub-websdk-container"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, user, token }) {
  return { auth, user, token };
}

export default {
  component: connect(mapStateToProps, { fetchUser, getVerificationToken })(
    requireAuth(VerificationPage)
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser()),
};
