import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class DefiRO extends Component {
    render() {
        return (
            <div className="intro-text">
                <div className="card-cover card-cover-2">
                    <img src="/img/defi/Defibot_EN.png" alt="" />
                    <a title=""><img src="/img/promo/arrow-card-down.png" alt="" /></a>
                </div>
                <div className="article-full">
                    <div className="transcard text-center">
                        <h2>
                            <span className="purple-text">AI</span> CRYPTO TRADING <span className="purple-text">BOT</span><br />
                            PENTRU CARE AI CU ADEVĂRAT ÎNCREDERE
                        </h2>
                        <p>
                            <em className="purple-text">
                                AI DeFiBot este un produs care oferă ceea ce ne dorim cu toții, și anume libertate adevărată, motivul pentru care ne-am îndrăgostit de crypto cu mulți ani în urmă.
                            </em>
                        </p>
                        <br />
                        <h4>
                            <strong className="purple-text">
                                Descentralizat, pur și fără interferențe.
                            </strong>
                        </h4>
                        <br />
                        <p className="blue-text">
                            DeFiBot îți permite să păstrezi banii în criptomonede fără a-i bloca sau a trimite fondurile către terți.
                        </p>
                        <br /><br />
                    </div>
                </div>
                <div className="article-full visible-box article-mrg-btn">
                    <div className="transcard text-center">
                        <img className="defi-logo-content" src="/img/defi/defi-bot-logo.png" alt="" />
                        <h2>
                            Am creat Invictus DeFiBot pentru că suntem adevărați credincioși ai libertății financiare.
                        </h2>
                        <h4>
                            <strong>
                                Algoritmul nostru AI poate tranzacționa BTC, ETH sau USDC din contul tău propriu, de la distanță, prin API, pe numeroase exchange-uri crypto precum Coinbase, Binance, Bybit și Bitmex.
                                De la 2017 dezvoltăm și tranzacționăm cu Invictus și avem încrederea de a împărtăși acest produs lumii.
                            </strong>
                        </h4>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>ANALIZĂ DE DATE 24/7 LA SCARĂ LARGĂ</h3>
                                    <p>
                                        AI nu folosește niciodată o pârghie mai mare de x2 și nu intră într-o poziție cu mai mult de 30% din capitalul portofelului tău.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>LIBERTATE ȘI SECURITATE PENTRU ACTIVELE TALE CRYPTO</h3>
                                    <p>
                                        Statistic, am menținut un profit constant de 28% anual, cu un drawdown extrem de conservator de până la 4%.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>TRANZACȚIONARE FĂRĂ EMOȚII ȘI OBOSINȚĂ</h3>
                                    <p>
                                        Controlul riscului este cel mai important aspect al AI, iar protejarea fondurilor clienților este de o importanță maximă.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr className="purple-line" />
                        <p className="text-center purple-text">
                            <em>
                                DISCLAIMER
                                <br /><br />
                                Serviciile oferite de DeFiBot nu sunt executate de BoneX și nu ne asumăm responsabilitatea pentru acțiunile terților, precum și pentru eventualele rezultate negative ale utilizării serviciilor acestora. Nicio comunicare sau informație furnizată de noi nu este destinată să reprezinte un sfat sau o recomandare financiară. Strategiile exemplu furnizate de BoneX pentru referința sau utilizarea ta nu trebuie interpretate ca sfaturi financiare. Anunțul curent reprezintă un avertisment că orice operațiune cu monede virtuale implică riscuri financiare, iar BoneX nu oferă garanții de profit sau protecție împotriva pierderilor.
                                <br /><br />
                                Informațiile și rezultatele furnizate prin utilizarea DeFiBot nu au fost verificate de BoneX. Decizia ta de a deveni utilizator al serviciilor DeFiBot se bazează pe propriul tău judecată și experiență în tranzacționarea activelor virtuale. Folosind linkul furnizat, care te direcționează către site-ul DeFiBot, ieși de pe site-ul BoneX și Termenii și Condițiile noastre, precum și documentele aferente, nu se aplică relației tale cu furnizorul de servicii care operează site-ul DeFiBot. Când folosești serviciile terților, ar trebui să te familiarizezi cu Termenii și Condițiile și Politica de Confidențialitate ale furnizorilor.
                                <br /><br />
                                <a href="">Confirm {">"}</a>
                            </em>
                        </p>
                        <br />
                    </div>
                </div>
                <div className="article-full visible-box">
                    <div className="transcard text-center">
                        <div className="offset-icons-top">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-1.png" alt="" />
                                        </figure>
                                        <h3>ANALIZĂ DE DATE 24/7 LA SCARĂ LARGĂ</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-2.png" alt="" />
                                        </figure>
                                        <h3>LIBERTATE ȘI SECURITATE PENTRU ACTIVELE TALE CRYPTO</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-3.png" alt="" />
                                        </figure>
                                        <h3>TRANZACȚIONARE FĂRĂ EMOȚII ȘI OBOSINȚĂ</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lines-box text-center">
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <a className="link-btn" href="https://defibot.com/register/?ref=bonexx" target="_blank">ÎNCEPE ACUM</a>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <h2>De ce inteligență artificială?</h2>
                        <h4>
                            <strong>
                                Piața crypto oferă posibilități nelimitate de profit, dar tristele realități arată că peste 80% dintre utilizatori pierd, din cauza emoțiilor și lipsei de cunoștințe despre ciclurile de piață și mișcările de preț. Din acest motiv, inteligența artificială (AI) este cea mai sustenabilă soluție pentru tine în sfera dinamică a crypto. Mișcările sale se bazează doar pe fapte și calcule exacte. Mai mult, botul nostru are capacitatea de a analiza tranzacțiile potențial perdante și de a se adapta la noile condiții de piață.
                            </strong>
                        </h4>
                        <h2>Investiția ta este în mâini sigure</h2>
                        <h4>
                            <strong>
                                DeFiBot utilizează doar strategii cu risc scăzut pentru tranzacționare și indicatori tehnici dovediți istoric, nu folosește levier și analizează cantități uriașe de informații în timp real, 24/7. DeFiBot tranzacționează printr-un cod API, pe care trebuie să îl generezi tu. Noi nu avem acces la fondurile tale, nu blocăm nicio criptomonedă pe care o gestionăm – le poți retrage oricând dorești. Acesta este motivul pentru care DeFiBot este un produs unic și de încredere.
                            </strong>
                        </h4>
                        <br /><br />
                    </div>
                </div>
                <a href="https://defibot.com/register/?ref=bonexx" target="_blank">
                    <img src="/img/defi/Defibot_Offer_EN.png" alt="" />
                </a>
            </div>
        )
    }
}

export default DefiRO;
