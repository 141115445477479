import _ from 'lodash';
import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import Loading from '../Loading';

const UserHeaderNew = ({ user }) => {
    const validated = user.validated ? '' : (
        <div className="col-md-12">
            <div className="user-header-info-figure-box p20">
                <h5><Translate id="userMessages.emailNotValidated" /></h5>
            </div>
        </div>
    );

    const verified = user.verified ? (
        <div className="col-sm-4">
            <div className="user-header-info-figure-box">
                <figure>
                    <i className="sprite verified-icon-new"></i>
                </figure>
                <div className="text">
                    <strong><Translate id="verified" /></strong>
                    <p><Translate id="verified" /> <Translate id="user" />
                    </p>
                </div>
            </div>
        </div>
    ) : (
        <div className="col-sm-4">
            <div className="user-header-info-figure-box">
                <figure>
                    <i className="fal fa-times-circle" style={{ fontSize: "25px" }}></i>
                </figure>
                <div className="text">
                    <strong><Translate id="not" /> <Translate id="verified" /></strong>
                    <p><Translate id="not" /> <Translate id="verified" /> <Translate id="user" />.  <Link to="/verification">
                        Verify here
                    </Link></p>
                </div>
            </div>
        </div>
    );

    const twoFactorAuth = user.totp === "google" ? (
        <div className="col-sm-4">
            <div className="user-header-info-figure-box">
                <figure>
                    <i
                        style={{
                            fontSize: "23px",
                            backgroundPosition: "-106px -65px",
                            width: "26px",
                            height: "30px",
                            position: "relative",
                            top: "10px",
                        }}
                        className="far fa-user-lock"
                    >
                    </i>
                </figure>
                <div className="text">
                    <strong>2FA</strong>
                    <p><Translate id="active2FA" /> </p>
                </div>

            </div>
        </div >
    ) : (
        <div className="col-sm-4">
            <div className="user-header-info-figure-box">
                <figure>
                    <i
                        style={{
                            fontSize: "23px", backgroundPosition: "-106px -65px",
                            width: "26px",
                            height: "30px"
                        }}
                        className="fal fa-user-unlock"
                    >
                    </i>
                </figure>
                <div className="text">
                    <strong>2FA</strong>
                    <p><Translate id="inactive2FA" /> </p>
                </div>

            </div>
        </div>
        // <div className="col-sm-4">
        //     <div className="user-header-info-figure-box">
        //         <figure>
        //             <i className="fal fa-times-circle" style={{ fontSize: "25px" }}></i>
        //         </figure>
        //         <div className="text">
        //             <strong><Translate id="not" /> <Translate id="verified" /></strong>
        //             <p><Translate id="not" /> <Translate id="verified" /> <Translate id="user" />.  <Link to="/verification">
        //                 Verify here
        //             </Link></p>
        //         </div>
        //     </div>
        // </div>
    );


    if (!_.isEmpty(user)) {
        return (
            <div>
                {validated}
                <div className="col-sm-4">
                    <div className="user-header-info-figure-box">
                        <figure>
                            <i className="sprite members-icon"></i>
                        </figure>
                        <div className="text">
                            <strong>{user.nickname}</strong>
                            <p>{user.email}</p>
                        </div>
                    </div>
                </div>
                {verified}
                {twoFactorAuth}
            </div>
        );
    } else {
        return <Loading />
    }
};

function mapStateToProps({ user }) {
    return { user };
}

export default connect(mapStateToProps)(UserHeaderNew);