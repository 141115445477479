import { AUTH_USER, UNAUTH_USER, AUTH_ERROR, CLEAR_AUTH_ERROR } from '../actions/types';

export default (state = { 
        authenticated: false, 
        totpRequired: false,
        error: '' 
    }, action) => {
        switch(action.type) {
            case AUTH_USER:
                return { ...state, authenticated: true };
            case UNAUTH_USER:
                return { ...state, authenticated: false };
            case AUTH_ERROR:
                return { ...state, error: action.payload };
            case CLEAR_AUTH_ERROR:
                return { ...state, error: '' };
            default:
                return state;
        }
};