import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class BuyPropertyBG extends Component {
    render() {
        return (
            <div className="intro-text">
                <div className="card-cover card-cover-2">
                    <img src="/img/covers/Bonex_Estate.PNG" alt="" />
                    <h1 style={{ marginTop: '20px' }}><Translate id="buyPropertyCrypto" /></h1>
                </div>
                <div className="article-full">
                    <div className="transcard text-center">
                        <div className="company-logo animate">
                            <figure>
                                <img src="/img/buy_property/bella-vita.png" alt="" />
                            </figure>
                        </div>
                        <h2 className="text-center animate">Вижте предложенията на Bella Vita</h2>
                        <p className="font-26"><em>Радваме се да ви съобщим, че вече можете да закупите имот с крипто от Elite Building. При интерес от Ваша страна бихме могли да ви предоставим допълнителна информация, ценова листа и примерни договори.</em></p>
                        <a className="btn-link" href="javascript:;" title="">РЕГИСТРИРАЙ СЕ</a>

                        <div className="property-list mt-5 pt-3">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <h3>
                                            <a href="javascript:;" title="">
                                                "Комфорт Резидънс - Малинова долина"
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <h3>
                                            <a href="javascript:;" title="">
                                                "Комфорт Резидънс - Малинова долина"
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <h3>
                                            <a href="javascript:;" title="">
                                                "Комфорт Резидънс - Малинова долина"
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <h3>
                                            <a href="javascript:;" title="">
                                                "Комфорт Резидънс - Малинова долина"
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-5"></div>
                </div>
                <div className="property-slider offset-bottom animate">
                    <div className="property-slider-entry">
                        <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                    </div>
                    <div className="property-slider-entry">
                        <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                    </div>
                    <div className="property-slider-entry">
                        <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                    </div>
                    <div className="property-slider-entry">
                        <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                    </div>
                </div>
                <div className="article-full">
                    <div className="transcard text-center animate">
                        <div className="property-text mb-5">
                            <time>20.10.2022</time>
                            <h1>Жилищна сграда с магазини и подземни гаражи, бул.Г.Софийски</h1>
                            <div className="property-location mb-5">
                                <span>България</span>
                                <span>гр. София</span>
                            </div>
                            <div className="text mb-5">
                                <p>Радваме се да ви съобщим, че вече можете да закупите имот с крипто от Elite Building. При интерес от Ваша страна бихме могли да ви предоставим допълнителна информация, ценова листа и примерни договори.</p>
                            </div>
                            <div className="seller-contacts mb-5">
                                <a href="mailto:seller@mail.com" title="">seller@mail.com</a>
                                <a href="tel:+359667265" title="">+359 667 265</a>
                            </div>
                            <div className="property-price">120 000 лв.</div>
                            <a className="btn-link-border" href="javascript:;" title="">Разгледай всички</a>
                        </div>
                    </div>
                </div>
                <div className="article-full">
                    <div className="transcard text-center">
                        <h2 className="text-center animate">BoneX вече ти предлага уникална възможност.</h2>
                        <p className="font-26"><i><em>Да използваш своите криптовалути, за да закупиш своя желан имот.</em></i></p>
                        <p className="font-26"><i><em>Използвай своя BoneX портфейл и купи своето ново жилище или инвестиционен имот в BTC, ETH, USDC, BCH, RVN, UNI, LINK, AAVE - по-бързо и с по-ниски такси отвсякога, само с няколко клика.</em></i></p>
                    </div>
                    <div className="property-logo-buy text-center">
                        <div className="logo-entry">
                            <img src="/img/assets/btc.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/eth.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/usdc.svg" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/bch.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/rvn.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/uni.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/link.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/aave.png" alt="" />
                        </div>
                    </div>
                    <div className="transcard text-center">
                        <h2 className="text-center animate">Разгледай предложенията:</h2>
                        <div className="lines-box text-center">
                            <p className="font-26"><em>Радваме се да ви съобщим, че вече можете да закупите имот с крипто, нашият екип е на разположение - свържи се с нас.</em></p>
                            <a className="btn-link" href="#">ДОБАВИ ОБЯВА</a>
                        </div>
                        <form className="filter-form">
                            <div className="row">
                                <div className="col-md-12 col-lg-3">
                                    <div className="custom-select">
                                        <select className="form-control">
                                            <option>Държава</option>
                                            <option>Държава 1</option>
                                            <option>Държава 2</option>
                                            <option>Държава 3</option>
                                            <option>Държава 4</option>
                                            <option>Държава 5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-4">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-addon">Цена от</div>
                                                    <input type="text" className="form-control" id="price_from" placeholder="1" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-addon">до</div>
                                                    <input type="text" className="form-control" id="price_to" placeholder="999" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-3">
                                    <div className="custom-select">
                                        <select className="form-control">
                                            <option>Подреди по: Цена</option>
                                            <option>Подреди по: Държава</option>
                                            <option>Подреди по: нещо си</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-2">
                                    <button type="submit" className="btn-link">Търси</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="transcard">
                        <div className="property-list-2 mt-5 pt-3">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <div className="property-text mb-3">
                                            <time>20.10.2022</time>
                                            <h3><a href="javascript:;" title="">Жилищна сграда с магазини и подземни гаражи, бул.Г.Софийски</a></h3>
                                            <div className="property-location mb-5">
                                                <span>България</span>
                                                <span>гр. София</span>
                                            </div>
                                            <div className="text mb-3">
                                                <p>Радваме се да ви съобщим, че вече можете да закупите имот с крипто от Elite Building. При интерес от Ваша страна бихме могли да ви предоставим допълнителна информация, ценова листа и примерни договори.</p>
                                            </div>
                                            <div className="seller-contacts mb-3">
                                                <a href="mailto:seller@mail.com" title="">seller@mail.com</a>
                                                <a href="tel:+359667265" title="">+359 667 265</a>
                                            </div>
                                            <div className="property-price">120 000 лв.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <div className="property-text mb-3">
                                            <time>20.10.2022</time>
                                            <h3><a href="javascript:;" title="">Жилищна сграда с магазини и подземни гаражи, бул.Г.Софийски</a></h3>
                                            <div className="property-location mb-5">
                                                <span>България</span>
                                                <span>гр. София</span>
                                            </div>
                                            <div className="text mb-3">
                                                <p>Радваме се да ви съобщим, че вече можете да закупите имот с крипто от Elite Building. При интерес от Ваша страна бихме могли да ви предоставим допълнителна информация, ценова листа и примерни договори.</p>
                                            </div>
                                            <div className="seller-contacts mb-3">
                                                <a href="mailto:seller@mail.com" title="">seller@mail.com</a>
                                                <a href="tel:+359667265" title="">+359 667 265</a>
                                            </div>
                                            <div className="property-price">120 000 лв.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <div className="property-text mb-3">
                                            <time>20.10.2022</time>
                                            <h3><a href="javascript:;" title="">Жилищна сграда с магазини и подземни гаражи, бул.Г.Софийски</a></h3>
                                            <div className="property-location mb-5">
                                                <span>България</span>
                                                <span>гр. София</span>
                                            </div>
                                            <div className="text mb-3">
                                                <p>Радваме се да ви съобщим, че вече можете да закупите имот с крипто от Elite Building. При интерес от Ваша страна бихме могли да ви предоставим допълнителна информация, ценова листа и примерни договори.</p>
                                            </div>
                                            <div className="seller-contacts mb-3">
                                                <a href="mailto:seller@mail.com" title="">seller@mail.com</a>
                                                <a href="tel:+359667265" title="">+359 667 265</a>
                                            </div>
                                            <div className="property-price">120 000 лв.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <div className="property-text mb-3">
                                            <time>20.10.2022</time>
                                            <h3><a href="javascript:;" title="">Жилищна сграда с магазини и подземни гаражи, бул.Г.Софийски</a></h3>
                                            <div className="property-location mb-5">
                                                <span>България</span>
                                                <span>гр. София</span>
                                            </div>
                                            <div className="text mb-3">
                                                <p>Радваме се да ви съобщим, че вече можете да закупите имот с крипто от Elite Building. При интерес от Ваша страна бихме могли да ви предоставим допълнителна информация, ценова листа и примерни договори.</p>
                                            </div>
                                            <div className="seller-contacts mb-3">
                                                <a href="mailto:seller@mail.com" title="">seller@mail.com</a>
                                                <a href="tel:+359667265" title="">+359 667 265</a>
                                            </div>
                                            <div className="property-price">120 000 лв.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="article-full overflow-visible">
                    <div className="transcard text-center">
                        <div className="company-logo sm animate">
                            <figure>
                                <img src="/img/buy_property/house.png" alt="" />
                            </figure>
                        </div>
                        <h2 className="text-center">Добави обява</h2>
                        <div className="mb-more text-center">
                            <div className="mrgt-3 mrgb-3">
                                <form className="form text-left p-5">
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="label-animated">
                                                        <input name="name" type="input" id="name" className="form-control" autocomplete="off" value="" />
                                                        <label className="control-label">Заглавие</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="label-animated">
                                                        <textarea name="description" id="description" className="form-control"></textarea>
                                                        <label className="control-label">Описание</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <div className="label-animated">
                                                        <input name="country" type="input" id="country" className="form-control" autocomplete="off" value="" />
                                                        <label className="control-label">Държава</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <div className="label-animated">
                                                        <input name="city" type="power" id="city" className="form-control" autocomplete="off" value="" />
                                                        <label className="control-label">Град</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <div className="label-animated">
                                                        <input name="email" type="input" id="email" className="form-control" autocomplete="off" value="" />
                                                        <label className="control-label">Мейл</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <div className="label-animated">
                                                        <input name="phone" type="input" id="phone" className="form-control" autocomplete="off" value="" />
                                                        <label className="control-label">Телефон</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <div className="label-animated">
                                                        <input name="price" type="input" id="price" className="form-control" autocomplete="off" value="" />
                                                        <label className="control-label">Цена</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-12">
                                                <div className="form-group custom-style-input">
                                                    <div className="inputsContainer">
                                                        <div className="inputsContBox">
                                                            <div className="row">
                                                                <div className="col-md-12 has-success">
                                                                    <div className="form-group">
                                                                        <div className="custom-upload">
                                                                            <input type="file" name="selfie" value="" id="selfie" accept=".jpg, .png, .jpeg" className="form-control" />
                                                                            <span>Снимки</span>
                                                                            <div className="custom-upload-fake">
                                                                                <figure>
                                                                                    <i className="sprite upload-icon"></i>
                                                                                </figure>
                                                                                <span>Качи</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="inputsContainer-bottom">
                                                            <p>
                                                                <i className="info-symbol">i</i>Макс. размер : 10MB, Тип: jpeg, jpg, или png
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix">&nbsp;</div>
                                    <div className="form-actions text-center">
                                        <button type="submit" className="btn-link">Изпрати</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BuyPropertyBG;
