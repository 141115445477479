import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import { fetchAffilaite } from '../../actions';

class AffiliateTableNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      rowsPerPage: 5,
    };
  }

  async handlePageChange(newPage) {
    this.setState({ currentPage: newPage });
  };


  UNSAFE_componentWillMount() {
    this.props.fetchAffilaite();
  }

  componentDidUpdate() {
    const dataEvent = document.createEvent('HTMLEvents');
    dataEvent.initEvent('datatable-data', true, true);
    document.dispatchEvent(dataEvent);
  }

  rows(currentData) {
    return _.map(currentData, (transaction, number) => {
      return (
        <tr key={transaction.id} className='custom-table-row'>
          <td>
            <div className="fee-box">
              {transaction.order_user_names}
            </div>
          </td>
          <td>
            <time>
              {new Intl.DateTimeFormat(this.props.translate("lang"), {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(transaction._created))}
            </time>
          </td>
          <td>
            <div className="fee-box">
              {transaction.transaction_account_type}
            </div>
          </td>
          <td>
            <strong className="amount">{parseFloat(transaction.amount).toFixed(2)} USDC</strong>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { currentPage, rowsPerPage } = this.state;
    const data = Object.values(this.props.affiliate);

    // Determine which rows to display
    const startIdx = (currentPage - 1) * rowsPerPage;
    const currentData = data.slice(startIdx, startIdx + rowsPerPage);

    // Number of total pages
    const totalPages = Math.ceil(data.length / rowsPerPage);


    return (
      <div className="" >
        <div className="table scroll-box" style={{ width: '100%', overflowX: "auto" }}>
          <table className={`custom-table custom-table-new`} style={{ width: '100%', overflowX: "auto" }}>
            <thead className='custom-table-head'>
              <tr className='custom-table-row'>
                <th>
                  <strong><Translate id="name" /></strong>
                </th>
                <th align="center">
                  <strong><Translate id="date" /></strong>
                </th>
                <th>
                  <strong><Translate id="account" /></strong>
                </th>
                <th>
                  <strong><Translate id="bonus" /></strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.rows(currentData)}
            </tbody>
          </table>
        </div>
        <div>
          <button className="pagination-button-new" type="button" onClick={() => this.handlePageChange(1)}>
            <i className="fal fa-chevron-double-left"></i>
          </button>
          <button className="pagination-button-new" onClick={() => currentPage > 1 ? this.handlePageChange(currentPage - 1) : ""}>
            <i className="fal fa-chevron-left"></i>
          </button>
          {Array.from({ length: totalPages }, (_, idx) => (
            <button
              key={idx}
              onClick={() => this.handlePageChange(idx + 1)}
              disabled={currentPage === idx + 1}
              className={`pagination-button-new ${currentPage === idx + 1 ? `active-button` : ``}`}
            >
              {idx + 1}
            </button>
          )).slice(currentPage < 4 ? 0 : currentPage - 3, currentPage < 4 ? 5 : currentPage + 2)
            // .slice(startingPage, currentPage < 5 ? 5 : startingPage + 5)

          }
          <button className="pagination-button-new" onClick={() => currentPage === totalPages ? "" : this.handlePageChange(currentPage + 1)}>
            <i className="fal fa-chevron-right"></i>
          </button>
          {
            <button className="pagination-button-new" onClick={() => this.handlePageChange(totalPages)}>
              <i className="fal fa-chevron-double-right"></i>
            </button>
          }
        </div>
      </div>
    );
  }
};


function mapStateToProps({ affiliate }) {
  return { affiliate };
}

export default connect(mapStateToProps, { fetchAffilaite })(withLocalize(AffiliateTableNew));