import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { fetchUnseenMessages } from '../../actions';

const pageWrapperStyle = {
  color: '#fff',
};

const rectangleBoxStyle = {
  border: '1px solid #fff',
  borderRadius: '25px',
  padding: '10px',
  margin: '10px 0',
  color: '#fff',
  fontSize: '19.9px',
};

const whiteButtonStyle = {
  backgroundColor: '#fff',
  color: '#000',
  border: 'none',
  borderRadius: '50px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '24px',
  marginTop: '20px',
};

const rowSpacing = {
  marginTop: '80px!important'
};

function FAQSection() {
  const faqs = [
    {
      question: "Cine poate aplica pentru Cardul Bonex?",
      answer: "Oricine poate aplica, dar trebuie să finalizezi verificarea KYC (Cunoaște-ți clientul) pentru a utiliza cardul."
    },
    {
      question: "Ce taxe sunt asociate cu utilizarea Cardului Bonex?",
      answer: `
Doar o taxă unică de 7 USD/EUR.
Tranzacție POS / E-commerce: 0,30 euro / 0,40 USD;
Taxă de reîncărcare a cardului: 1,5%`
    },
    {
      question: "Pot seta limita de cheltuieli pentru Cardul meu Bonex?",
      answer: `
Pentru a putea seta limite.
      `.trim()
    },
    {
      question: "Cum adaug fonduri la Cardul meu Bonex?",
      answer: `Vei primi o adresă unică de depunere pentru criptomonede. Odată ce depui crypto în acea adresă, fondurile tale vor fi convertite automat în EUR sau USD pe cardul tău.
      `.trim()
    },
    {
      question: "Ce monede pot folosi cu Cardul Bonex?",
      answer: "În prezent poți folosi euro (EUR) sau dolari SUA (USD)."
    }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div style={{ marginTop: '20px', padding: '10px' }}>
      {faqs.map((item, idx) => (
        <div key={idx} style={{ marginBottom: '20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
              alignItems: 'center'
            }}
            onClick={() => toggleFAQ(idx)}
          >
            <h5 style={{ color: '#fff', margin: 0, fontSize: '15px', paddingLeft: '10px' }}>
              {item.question}
            </h5>
          </div>

          <hr style={{ border: '1px solid #fff', margin: '10px 0' }} />
          {openIndex === idx && (
            <div style={{ color: '#ccc', paddingLeft: '10px', whiteSpace: 'pre-wrap' }}>
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

class CardsBonexRO extends Component {
  constructor(props) {
    super(props);
    this.scrollToDonateSection = this.scrollToDonateSection.bind(this);
  }

  scrollToDonateSection() {
    const donateSection = document.getElementById("donate");
    if (donateSection) {
      donateSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const autoScroll = queryParams.get("scrollToDonate");

    if (autoScroll === "true") {
      this.scrollToDonateSection();
    }
  }

  render() {
    return (
      <div style={pageWrapperStyle}>
        <div className="intro-text" style={{ marginTop: '0px!important' }}>
          <div className="article-full cardBackground">
            <div className="transcard">
              <div className="row align-items-center text-left my-5 my-section">
                <div className="col-12 col-md-6 order-1 order-md-1">
                  <h2 className='heading-style'>
                    VIAȚĂ CRYPTO NELIMITATĂ<br />
                    LA ÎNDEMÂNA TA
                  </h2>
                  <br />
                  <div className="col-12 col-md-5 order-2 order-md-2 text-center text-md-right viewImgMob">
                    <img
                      src="/img/bonexCards.jpg"
                      alt="Card de credit"
                      style={{ borderRadius: '10px', marginTop: '10px', marginBottom: '20px' }}
                    />
                  </div>

                  <div
                    style={{
                      ...rectangleBoxStyle,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src="/img/cards03.png"
                      alt="Icoană"
                      style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px'
                      }}
                    />
                    <span>Cheltuiește crypto cu taxe reduse</span>
                  </div>
                  <div
                    style={{
                      ...rectangleBoxStyle,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src="/img/cards02.png"
                      alt="Icoană"
                      style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px'
                      }}
                    />
                    <span>Fără limite de cheltuieli, niciodată</span>
                  </div>
                  <div
                    style={{
                      ...rectangleBoxStyle,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src="/img/cards01.png"
                      alt="Icoană"
                      style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px'
                      }}
                    />
                    <span>Card instant: emis în 1 minut</span>
                  </div>
                  <br />
                  <a
                    className="white-button"
                    href="https://cards.bonex.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Obține-ți cardul
                  </a>
                </div>
                <div className="col-12 col-md-1"> </div>
                <div className="col-12 col-md-5 order-2 order-md-2 text-center text-md-right viewImgPc">
                  <img
                    src="/img/bonexCards.jpg"
                    alt="Card de credit"
                    style={{ borderRadius: '10px', marginTop: '100px' }}
                  />
                </div>
              </div>

              <div className="row align-items-center text-left my-5 my-section" style={rowSpacing}>
                <div className="col-12 col-md-9">
                  <h2 className='heading-style'>
                    Alimentează-ți instantaneu cardul<br />cu <b>ORICE CRYPTO</b><br />din <b>ORICE PORTOFEL</b>
                  </h2>
                  <div style={{ marginTop: '30px', fontSize: '24px', color: '#fff' }}>
                    Monede fiat suportate: USD & EUR
                  </div>
                  <div style={{ marginTop: '10px', fontSize: '24px', color: '#fff' }}>
                    Fără costuri ascunse, 100% transparență.
                  </div>
                  <div style={{ marginTop: '10px', fontSize: '24px', color: '#fff' }}>
                    Doar o taxă unică de 7 USD/EUR.<br /><br />
                  </div>
                </div>
              </div>

              <div
                className="row align-items-center text-left my-5 my-section"
                style={rowSpacing}
              >
                <div className="col-12 col-md-12">
                  <h2 className='heading-style' style={{ textAlign: 'center' }}>
                    OBȚINE CARDUL TĂU DE DEBIT CRYPTO.<br /> PORNEȘTE ACUM.
                  </h2>
                  <br />
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-4 mb-4">
                      <div className="my-box step1">
                        <p style={{ margin: '0 10px 54px 10px', fontSize: '20px' }}>
                          Verifică-ți adresa de email și<br /> numărul de telefon pe<br /> pagina următoare.
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 mb-4">
                      <div className="my-box step2">
                        <p style={{ margin: '0 10px 60px 10px', fontSize: '20px' }}>
                          Instalează aplicația Mobilum<br /> Pay și conectează-te cu<br /> aceleași date.
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 mb-4">
                      <div className="my-box step3">
                        <p style={{ margin: '0 10px 35px 10px', fontSize: '20px' }}>
                          Finalizează verificarea KYC<br /> și începe să folosești<br /> cardul tău virtual<br /> imediat.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12" style={{ marginTop: '40px', textAlign: 'center' }}>
                <a
                  className="white-button"
                  href="https://cards.bonex.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Începe
                </a>
              </div>

              <div className="col-12" style={{ marginTop: '40px', textAlign: 'center' }}>
                <h2
                  className="section-title text-left"
                  style={{
                    margin: 0,
                    marginTop: '50px',
                    color: '#fff',
                    fontWeight: 'bold'
                  }}
                >
                  ÎNTREBĂRI FRECVENTE
                </h2>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <FAQSection />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    accounts: state.accounts,
    account: state.account,
    currency: state.currency,
    unseenCount: state.messages.unseenCount,
  };
}

export default connect(
  mapStateToProps,
  { fetchUnseenMessages }
)(CardsBonexRO);
