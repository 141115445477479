import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import { fetchUser, fetchUnseenMessages } from "../actions";
import AccountsHeaderWidget from "./accounts/AccountsHeaderWidget";
import LanguageToggle from "./LanguageToggle";
import Currencies from "./Currencies";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unseenCount: 0,
      translationsLoaded: false
    };
  }

  async componentDidMount() {
    if (this.props.auth.authenticated == true && this.props.user) {
      let unseen = await this.props.fetchUnseenMessages();
      if (unseen && unseen.result) {
        this.setState({ unseenCount: unseen.result.length });
      }
    }

    // Mark translations as loaded when activeLanguage is set
    this.setState({ translationsLoaded: true });
  }

  navbar() {
    if (!this.state.translationsLoaded) {
      return null; // Avoid hydration mismatches
    }

    return this.props.auth.authenticated == true && this.props.user ? (
      <React.Fragment>
        <Link to="/messages" className={`nav-link inbox-btn ${this.state.unseenCount != 0 ? 'inbox-pulse' : ''}`}>
          <span className="title">
            <Translate id="inbox" />
            {this.state.unseenCount != 0 ? <var>{this.state.unseenCount}</var> : ""}
          </span>
        </Link>
        <Link to="/profile" className="nav-link">
          <span className="title">
            <Translate id="profile" />
          </span>
        </Link>
        <span>
        </span>
        <Link to="/logout" className="btn-link-box fill">
          <span className="title">
            <Translate id="signout" />
          </span>
        </Link>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Link to="/login" className="nav-link">
          <span className="title">
            <Translate id="signin" />
          </span>
        </Link>
        <span>
        </span>
        <Link to="/signup" className="btn-link-box fill">
          <span className="title">
            <Translate id="signup" />
          </span>
        </Link>
      </React.Fragment>
    );
  }

  render() {
    return (
      <header id="header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Link to="/" id="logo-main">
                <img className="d-none d-lg-block dark-logo" src="/img/logo-sm.png" alt="BoneX Crypto Exchange" />
                <img className="d-none d-lg-block light-logo" src="/img/logo-sm-light.png" alt="BoneX Crypto Exchange" />
                <img className="d-lg-none" src="/img/logo-m.png" alt="BoneX Crypto Exchange" />
              </Link>
              <nav id="navigation-box">
                <button><span></span></button>
                <ul className="nav navbar-nav" id="main-menu">
                  {/* <li className="nav-item">
                    <Link to="/" className="nav-link">
                      <span className="title">
                        <Translate id="home.home" />
                      </span>
                    </Link>
                  </li> */ }
                  <li className="nav-item">
                    <Link to="/spot-trading" className="nav-link">
                      <span className="title">
                        <Translate id="spotTrading" />
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a href="https://www.bonex.net/" className="nav-link pink-link" target="_blank">
                      <span className="title">
                        <Translate id="BoneX Margin" />
                      </span>
                    </a>
                  </li>
                  <li className="nav-item has-submenu">
                    <a className="nav-link">
                      <span className="title">
                        <Translate id="services" />
                      </span>
                    </a>
                    <ul className="nav-item">
                      <li className="nav-item">
                        <Link to="/book-flight" className="nav-link">
                          <img src="/img/menu/icon-5.png" alt="" />
                          <span className="title">
                            <Translate id="bookFlight" />
                          </span>
                          <span className="text">
                            <Translate id="bookFlightText" />
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/buy-property" className="nav-link">
                          <img src="/img/menu/icon-4.png" alt="" />
                          <span className="title">
                            <Translate id="buyProperty" />
                          </span>
                          <span className="text">
                            <Translate id="buyPropertyText" />
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/open-banking" className="nav-link">
                          <img src="/img/menu/icon-3.png" alt="" />
                          <span className="title">
                            <Translate id="banking" />
                          </span>
                          <span className="text">
                            <Translate id="bankingText" />
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/cars/rental" className="nav-link">
                          <img src="/img/menu/icon-7.png" alt="" />
                          <span className="title">
                            <Translate id="header-menu-super-cars-title" />
                          </span>
                          <span className="text">
                            <Translate id="header-menu-super-cars-description" />
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/crypto-accounting" className="nav-link">
                          <img src="/img/menu/icon-2.png" alt="" />
                          <span className="title">
                            <Translate id="cryptoAccounting" />
                          </span>
                          <span className="text">
                            <Translate id="cryptoAccountingText" />
                          </span>
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link to="/defi" className="nav-link">
                          <img src="/img/menu/icon-1.png" alt="" />
                          <span className="title">
                            <Translate id="aiTrading" />
                          </span>
                          <span className="text">
                            <Translate id="aiTradingText" />
                          </span>
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link to="/cars/bmw" className="nav-link">
                          <img src="/img/menu/icon-6.png" alt="" />
                          <span className="title">
                            <Translate id="header-menu-bmw-title" />
                          </span>
                          <span className="text">
                            <Translate id="header-menu-bmw-description" />
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link to="/news" className="nav-link">
                      <span className="title">
                        <Translate id="news" />
                      </span>
                    </Link>
                  </li>
                </ul>
              </nav>
              <ul className="system-links">
                <li><Currencies /></li>
                <li><LanguageToggle /></li>
                <li>{this.navbar()}</li>
              </ul>
            </div >
          </div >
        </div >
      </header >
    );
  }
}

function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default connect(
  mapStateToProps,
  { fetchUser, fetchUnseenMessages }
)(withLocalize(Header));
