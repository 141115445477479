import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";

class LanguageToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isClient: false // Ensures client-side rendering consistency
    };
  }

  componentDidMount() {
    this.setState({ isClient: true }); // Mark as client-rendered
  }

  onLanguageSetClick(code) {
    this.setState({ open: false }); // Close dropdown after selection
    this.props.setActiveLanguage(code);
  }

  render() {
    const { languages, activeLanguage } = this.props;
    const { open, isClient } = this.state;

    // Avoid rendering during SSR to prevent hydration mismatch
    if (!isClient || !activeLanguage) {
      return null;
    }

    return (
      <div className={open ? "select-dropdown-box open" : "select-dropdown-box"}>
        <button onClick={() => this.setState({ open: !open })}>
          <span>
            <img src={`/img/flags/${activeLanguage.name}.png`} alt="" />
          </span>
          {activeLanguage.name}
        </button>
        <ul>
          {languages.map((lang) =>
            activeLanguage.code === lang.code ? (
              <li key={lang.code} style={{ display: "none" }}></li> // Prevents an empty <li>
            ) : (
              <li key={lang.code}>
                <button
                  className="btn btn-action btn-rounded"
                  onClick={() => this.onLanguageSetClick(lang.code)}
                >
                  <span>
                    <img src={`/img/flags/${lang.name}.png`} alt="" />
                  </span>
                  {lang.name}
                </button>
              </li>
            )
          )}
        </ul>
      </div>
    );
  }
}

export default withLocalize(LanguageToggle);
