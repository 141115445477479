import axios from "axios";
import "babel-polyfill";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom";
import { renderToStaticMarkup } from "react-dom/server";
import { LocalizeProvider } from "react-localize-redux";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import { Router } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import globalTranslations from "../translations/global.json";
import Routes from "./Routes";
import { AUTH_USER } from "./actions/types";
import reducers from "./reducers";
import SafeHydration from "./components/hydration/SafeHydration";

const axiosInstance = axios.create({
  baseURL: "/api"
});

const composeEnchancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  window.INITIAL_STATE,
  composeEnchancers(applyMiddleware(thunk.withExtraArgument(axiosInstance)))
);

const supportedLanguages = ["bg", "en", "ro"]; //, "ro", "gr"];
const languages = [{ name: "BG", code: "bg" }, { name: "EN", code: "en" }, { name: "RO", code: "ro" }]; // , { name: "RO", code: "ro" }, { name: "GR", code: "gr" }];
let defaultLanguage = languages[1].code;

if (window.localStorage) {
  const token = localStorage.getItem("token");

  if (token) {
    store.dispatch({ type: AUTH_USER });
  }

  defaultLanguage = Cookies.get("languageCode") || languages[1].code;

  // let queryString = window.location.search;
  // if (queryString != '') {
  //   queryString = queryString.replace('?', '');
  //   const queryStringArr = queryString.split('&');
  //   let langParam = '';
  //   queryStringArr.forEach(param => {
  //     let paramParts = param.split('=');
  //     if (paramParts[0] == 'lang') {
  //       langParam = paramParts[1];
  //     }
  //   });

  //   if (langParam != '' && supportedLanguages.indexOf(langParam) != -1) {
  //     defaultLanguage = langParam;
  //     Cookies.set("languageCode", defaultLanguage, { expires: 3650, path: "/" });
  //   }
  // }
}

if (true) {
  defaultLanguage = Cookies.get("languageCode") || languages[1].code;
}

ReactDOM.hydrate(
  <Provider store={store}>
    <SafeHydration>
      <LocalizeProvider
        initialize={{
          languages: languages,
          translation: globalTranslations,
          options: {
            defaultLanguage,
            renderToStaticMarkup
          }
        }}
      >
        <Router history={createBrowserHistory()}>
          <div>{renderRoutes(Routes)}</div>
        </Router>
      </LocalizeProvider>
    </SafeHydration>
  </Provider>,
  document.querySelector("#root")
);
