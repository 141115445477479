import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import AssetsHomeTableItem from './AssetsHomeTableItem';

class AssetsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: ''
    };
  }

  handleSearchChange(e) {
    this.setState({ searchQuery: e.target.value });
  };

  render() {
    const { searchQuery } = this.state;

    // Filter the rates based on search query matching either `type` or `title`
    const filteredRates = _.filter(this.props.rates, (rate) => {
      const matchesType = rate.type.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesTitle = rate.title && rate.title.toLowerCase().includes(searchQuery.toLowerCase());

      return (!rate.is_fiat || rate.type === 'USDC') && !rate.trade_disabled && (matchesType || matchesTitle);
    });

    return (
      <div className="col-sm-12 mb-5">
        {/* <div className="search-filter mb-3">



          <span style={{ color: 'white' }}>Search</span> {" "}

          <input
            type="text"
            className="input-search-currency"
            placeholder=""
            value={searchQuery}
            onChange={this.handleSearchChange.bind(this)}
            style={{ color: "white", backgroundColor: "transparent" }}
          />

        </div> */}

        <table className="table-box-style table-box-style-list mb-5">
          <thead>
            <tr>
              <th colSpan={2} className='text-center'>
                <span style={{ color: 'white' }}>
                  <Translate id="search" />
                </span> {" "}
                <input
                  type="text"
                  className="input-search-currency"
                  placeholder=""
                  value={searchQuery}
                  onChange={this.handleSearchChange.bind(this)}
                  style={{ color: "white", backgroundColor: "transparent", border: '1px solid #004f74', paddingLeft: '5px' }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredRates.map((rate) => (
              <AssetsHomeTableItem {...rate} key={rate.id} user={this.props.user} config={this.props.config} />
            ))}
          </tbody>
        </table>
      </div >
    );
  }
};

function mapStateToProps({ user, rates, config }) {
  return { user, rates, config };
}

export default connect(mapStateToProps)(AssetsTable);
