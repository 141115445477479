export const AUTH_USER = "auth_user";
export const UNAUTH_USER = "unauth_user";
export const AUTH_ERROR = "auth_error";
export const CLEAR_AUTH_ERROR = "clear_auth_error";

export const SEND_CHARITY_TRANSACTION = 'SEND_CHARITY_TRANSACTION';
export const SEND_CHARITY_TRANSACTION_SUCCESS = 'SEND_CHARITY_TRANSACTION_SUCCESS';
export const SEND_CHARITY_TRANSACTION_FAILURE = 'SEND_CHARITY_TRANSACTION_FAILURE';

export const FETCH_BALANCES_SUCCESS = 'FETCH_BALANCES_SUCCESS';
export const FETCH_BALANCES_FAILURE = 'FETCH_BALANCES_FAILURE';

export const SET_UNSEEN_COUNT = 'SET_UNSEEN_COUNT';

export const FETCH_USER_DATA = "fetch_user_data";
export const UPDATE_USER_DATA = "update_user_data";
export const CLEAR_USER_DATA = "clear_user_data";

export const FETCH_TOTP = "fetch_totp";
export const VERIFY_TOTP = "verify_totp";
export const UPDATE_TOTP = "update_totp";
export const CLEAR_TOTP = "clear_totp";

export const FETCH_TRANSACTIONS = "fetch_transactions";
export const CLEAR_USER_TRANSACTIONS = "clear_user_transactions";

export const FETCH_ORDERS = "fetch_orders";
export const CLEAR_USER_ORDERS = "clear_user_orders";

export const FETCH_AFFILIATE = "fetch_affiliate";
export const CLEAR_AFFILIATE = "clear_affilaite";

export const FETCH_BONUSES = "fetch_bonuses";
export const CLEAR_BONUSES = "clear_bonuses";

export const FETCH_ASSET = "fetch_asset";
export const CLEAR_ASSET = "clear_asset";

export const SEND_TRANSACTION = "send_transaction";
export const FETCH_TRANSACTION = "fetch_transaction";
export const CLEAR_TRANSACTION = "clear_transaction";

export const FETCH_BALANCES = "fetch_balances";
export const CLEAR_USER_BALANCES = "clear_user_balances";

export const FETCH_RATES = "fetch_rates";

export const FETCH_NETWORK_ADDRESSES = "fetch_network_addresses"

export const FETCH_ACCOUNTS = "fetch_accounts";
export const GENERATE_ACCOUNTS = "generate_accounts";

export const FETCH_ACCOUNT = "fetch_account";
export const CREATE_ACCOUNT = "create_account";
export const UPDATE_ACCOUNT = "update_account";
export const WITHDRAW_ACCOUNT = "withdraw_account";
export const DEPOSIT_ACCOUNT = "deposit_account";
export const SELL_ACCOUNT = "sell_account";
export const BUY_ACCOUNT = "buy_account";
export const CLEAR_USER_ACCOUNTS = "clear_user_accounts";
export const CLEAR_USER_ACCOUNT = "clear_user_account";

export const UPDATE_GENERAL_SETTINGS = "update_general_settings";
export const UPDATE_SECURITY_SETTINGS = "update_security_settings";
export const UPDATE_PASSWORD_SETTINGS = "update_password_settings";
export const UPDATE_PRIVACY_SETTINGS = "update_privacy_settings";

export const UPDATE_PERSONAL_VERIFICATION = "update_personal_verification";
export const UPDATE_ADDRESS_VERIFICATION = "update_address_verification";

export const FETCH_NEWS = "fetch_news";
export const FETCH_NEWS_ENTRY = "fetch_news_entry";
export const CLEAR_NEWS = "clear_news";

export const FETCH_MESSAGES = "fetch_messages";
export const FETCH_MESSAGES_ENTRY = "fetch_messages_entry";
export const CLEAR_MESSAGES = "clear_messages";

export const FETCH_PROPERTIES = "fetch_properties";
export const FETCH_PROPERTIES_ENTRY = "fetch_properties_entry";
export const CLEAR_PROPERTIES = "clear_properties";

export const FETCH_PAGES_ENTRY = "fetch_pages_entry";

export const FETCH_CONFIG = "fetch_config";

export const SET_ERROR = "set_error";
export const UNSET_ERROR = "unset_error";

export const SET_CURRENCY = "set_currency";
export const FETCH_CURRENCIES = "fetch_currencies";
export const CLEAR_CURRENCIES = "clear_currencies";

export const FETCH_AIRPORTS = "fetch_airports";
export const CLEAR_AIRPORTS = "clear_airports";

export const FETCH_CARS = "fetch_cars";
export const CLEAR_CARS = "clear_cars";
export const FETCH_CAR = "fetch_car";
export const CLEAR_CAR = "clear_car";
