import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Translate, withLocalize } from "react-localize-redux";
import BookFlight from '../../components/flights/BookFlight';
import { CarsCarousel } from '../../components/cars/CarsCarousel';
import { CarsFiltersExtended } from '../../components/cars/CarsFiltersExtended';
import { CarsList } from '../../components/cars/CarsList';
import { fetchCars, fetchRates } from '../../actions';

class CarsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.activeLanguage.code
        };
    }

    head() {
        return (
            <Helmet>
                <title>{`Cars - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Cars - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidMount() {
        document.body.classList.add('light-theme');
        this.props.fetchCars('Sale', 'BMW');
    }

    componentWillUnmount() {
        document.body.classList.remove('light-theme');
    }

    componentDidUpdate() {
        if (this.props.activeLanguage.code != this.state.lang) {
            this.setState({
                lang: this.props.activeLanguage.code,
            });
        }
    }
    render() {

        const isMarginRoute =
            this.props &&
            this.props.location &&
            this.props.location.pathname &&
            (this.props.location.pathname.startsWith('/margin') || this.props.location.pathname.startsWith('/margin-mobile'));

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            {this.head()}
                            <div className="row">
                                <div className="col-sm-12 col-lg-8">
                                    <h1 className="page-title-box">
                                        <span>buy your new</span>
                                        <span>BMW car with <span>crypto</span></span>
                                    </h1>
                                </div>
                                <div className="col-sm-12 col-lg-4 text-lg-right">
                                    <div className="brand-logo">
                                        <p>
                                            Powered<br /> by:
                                        </p>
                                        <figure>
                                            <img src="/img/bmw-logo.png" alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <CarsFiltersExtended />
                            <CarsList items={this.props.cars} type="bmw" rates={this.props.rates} currency={this.props.currency} isMarginRoute={isMarginRoute} />
                            {/*<div className="cars-list">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="car-entry">
                                            <CarsCarousel />
                                            <div className="text">
                                                <header>
                                                    <p>111 785 BGN</p>
                                                    <h3><Link to="cars/details">BMW iX1 eDrive20</Link></h3>
                                                </header>
                                                <ul>
                                                    <li>Тип гориво Електрически</li>
                                                    <li>Тип задвижване sDrive</li>
                                                    <li>Консумация на електроенергия 15.9 кВтч/100 км [1] </li>
                                                    <li>Електрически пробег (комбиниран) 460 км </li>
                                                    <li>Производителност -</li>
                                                    <li>Ускорение 8.6s (0-100 км/ч)</li>
                                                    <li>Емисии на CO2 (комбинирани) 0 g/km</li>
                                                </ul>
                                                <footer>
                                                    <Link to="cars/details">View Details</Link>
                                                </footer>
                                            </div>
                                            <span className="car-label">NEW CAR</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 text-center">
                                        <a className="btn-link-box" href="javascript:;" title="">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};




function mapStateToProps({ cars, rates, currency }) {
    return { cars, rates, currency };
}

export default {
    component: connect(
        mapStateToProps,
        { fetchCars, fetchRates }
    )(withLocalize(CarsPage)),
    loadData: ({ dispatch }) => dispatch(fetchCars())
};