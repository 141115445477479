import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import AddPropertyForm from "./AddPropertyForm";

class BuyPropertyBG extends Component {
    render() {
        return (
            <div className="intro-text">
                <div className="card-cover card-cover-2">
                    <img src="/img/covers/Bonex_Estate.PNG" alt="" />
                    <h1 style={{ marginTop: '-30px' }}><Translate id="buyPropertyCrypto" /></h1>
                </div>
                <div className="article-full">
                    <div className="transcard text-center pt-5">
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 mb-3">
                                <a className="btn-link purple-gradient has-arrow has-big-icon w-100 disabled" href="" title="">
                                    <i className="far fa-home-alt"></i>
                                    Разгледай<br /> предложенията
                                </a>
                            </div>
                            <div className="col-sm-12 col-lg-6 mb-3">
                                <Link className="btn-link purple-gradient has-arrow has-big-icon w-100" to="buy-property/add-property">
                                    <i className="fal fa-plus-circle"></i>
                                    <Translate id="addProperty" />
                                </Link>
                            </div>
                        </div>
                        <h2 className="text-center animate">Стани партньор</h2>
                        <p className="font-20">Предлагаш недвижими имоти?</p>
                        <p className="font-20">Стани партньор на BoneX и разшири възможностите си за продажба. Достигни до правилните хора, получавай мигновени и сигурни разплащания за своите имоти с минимални такси посредством криптовалути и управлявай финансите си с лекота в предпочитана валута от всяка точка на света. BoneX ще се погрижи за всичко, за да се съсредоточиш единствено върху нещата, в които си добър!</p>
                        <div className="lines-box">
                            <div className="row mb-5">
                                <div className="col-sm-12 col-lg-6 col-lg-offset-3 mb-5">
                                    <a className="btn-link blue-gradient has-arrow has-big-icon" href="mailto:property@bonex.org" title="">
                                        <i className="fal fa-phone-alt"></i>
                                        свържи се с нас
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="article-full">
                    <div className="transcard text-center">
                        <h2 className="text-center animate">BoneX вече ти предлага уникална възможност.</h2>
                        <p className="font-26"><i><em>Да използваш своите криптовалути, за да закупиш своя желан имот.</em></i></p>
                        <p className="font-26"><i><em>Използвай своя BoneX портфейл и купи своето ново жилище или инвестиционен имот в BTC, ETH, USDC, BCH, RVN, UNI, LINK, AAVE - по-бързо и с по-ниски такси отвсякога, само с няколко клика.</em></i></p>
                    </div>
                    <div className="property-logo-buy text-center">
                        <div className="logo-entry">
                            <img src="/img/assets/btc.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/eth.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/usdc.svg" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/bch.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/rvn.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/uni.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/link.png" alt="" />
                        </div>
                        <div className="logo-entry">
                            <img src="/img/assets/aave.png" alt="" />
                        </div>
                    </div>
                    <div className="transcard text-center">
                        <p className="font-26"><i><em>При желание от ваша страна, можем да направим среща в наш офис с юридическия ни екип, който ще обясни на вас и продавача на имота, каква е пътната карта за осъществяване на такава сделка по възможно най-разбираемия начин. Ние сме първият крипто ексчейндж, който предоставя тази услуга и вече имаме опит. Възползвайте се от него! </em></i></p>
                        <a className="btn-link" href="mailto:property@bonex.org">СВЪРЖИ СЕ С НАС</a>
                        <br /><br /><br /><br />
                    </div>
                </div>
                {/*<div className="article-full">
                    <div className="transcard text-center">
                        <h2 className="text-center animate">Разгледай предложенията</h2>
                        <div className="lines-box text-center">
                            <Link className="btn-link" to="buy-property/add-property"><Translate id="addProperty" /></Link>
                        </div>
                    </div>
                    <div className="transcard">
                        <div className="property-list-2 mt-5 pt-3">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="property-entry">
                                        <figure>
                                            <a href="javascript:;" title="">
                                                <img className="" src="/img/buy_property/property-1.jpg" alt="" />
                                            </a>
                                        </figure>
                                        <div className="property-text mb-3">
                                            <time>20.10.2022</time>
                                            <h3><a href="javascript:;" title="">Жилищна сграда с магазини и подземни гаражи, бул.Г.Софийски</a></h3>
                                            <div className="property-location mb-5">
                                                <span>България</span>
                                                <span>гр. София</span>
                                            </div>
                                            <div className="text mb-3">
                                                <p>Радваме се да ви съобщим, че вече можете да закупите имот с крипто от Elite Building. При интерес от Ваша страна бихме могли да ви предоставим допълнителна информация, ценова листа и примерни договори.</p>
                                            </div>
                                            <div className="seller-contacts mb-3">
                                                <a href="mailto:seller@mail.com" title="">seller@mail.com</a>
                                                <a href="tel:+359667265" title="">+359 667 265</a>
                                            </div>
                                            <div className="property-price">120 000 лв.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        )
    }
}

export default BuyPropertyBG;
