import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUnseenMessages, sendCharityTransaction } from '../../actions';

import TransactionsTablePublicNew from '../transactions/TransactionsTablePublicNew';

const headingStyle = {
  margin: 0,
  color: '#000',
  marginTop: '50px',
  fontWeight: 'bold'
};

function paragraphStyle(extraMargin = false) {
  return {
    marginTop: '20px',
    marginBottom: extraMargin ? '40px' : '0'
  };
}

function imageStyle(maxHeight, extraMarginLeft = 0, extraMarginTop = 0, extraMarginBottom = 0) {
  return {
    maxHeight,
    marginLeft: extraMarginLeft,
    marginTop: extraMarginTop,
    marginBottom: extraMarginBottom
  };
}

const rowSpacing = {
  marginTop: '120px'
};

const yellowButtonStyle = {
  backgroundColor: '#FFC107',
  color: '#000',
  borderRadius: '50px',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '16px'
};

function OverlappingButtons(props) {
  const { yellowText, blueText, blueWidth = '245px' } = props;
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button className="charity-btn-yellow" style={yellowButtonStyle}>
        {yellowText}
      </button>
      <button className="charity-btn-blue"
        style={{
          backgroundColor: '#163973',
          color: '#fff',
          borderRadius: '50px',
          padding: '10px 20px',
          width: blueWidth,
          border: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
          fontSize: '16px',
          position: 'absolute',
          top: 0,
          left: 'calc(100% - 15px)'
        }}
      >
        {blueText}
      </button>
    </div>
  );
}

function getBalanceFor(accounts, coinType) {
  if (!accounts || typeof accounts !== 'object') return 0;

  const arr = Object.values(accounts);
  const found = arr.find((acc) => acc.type === coinType);
  if (!found) return 0;

  return parseFloat(found.balance) || 0;
}

class CryptoPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '10',
      selectedTab: 'USDC'
    };
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.copyAddress = this.copyAddress.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  handleAmountChange(e) {
    this.setState({ amount: e.target.value });
  }

  handleSend() {
    if (!this.props.isLoggedIn) {
      alert("Трябва да сте логнат, за да изпратите транзакция.");
      return;
    }

    const userConfirmed = window.confirm("Сигурни ли сте, че искате да направите дарение?");
    if (!userConfirmed) {
      return;
    }

    const payload = {
      accountType: this.state.selectedTab,
      amount: this.state.amount
    };

    this.props.sendCharityTransaction(payload)
      .then((data) => {

        //alert(`Дарението е изпратено успешно: ${payload.amount} ${payload.accountType}`);
      })
      .catch((error) => {
        console.error("Грешка при изпращане на транзакцията:", error);
      });
  }

  copyAddress(addr) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(addr)
        .then(() => alert(`Address copied: ${addr}`))
        .catch((err) => alert(`Copy failed: ${err}`));
    } else {
      alert('Clipboard API not supported in this browser.');
    }
  }

  changeTab(tab) {
    const defaultAmounts = {
      USDC: '10',
      BTC: '0.001',
      ETH: '0.01'
    };
    this.setState({
      selectedTab: tab,
      amount: defaultAmounts[tab]
    });
  }

  render() {
    const { selectedTab, amount } = this.state;
    const { isLoggedIn, balances } = this.props;

    const balance = balances[selectedTab.toLowerCase()] || 0;
    const addresses = {
      USDC: '0x8c72e61bb556109c7007d8ee8781a9f62071fa0c',
      BTC: '19Cgm6TGpkvSd7cAW1ZNKZXaZwjfprnrHx',
      ETH: '0x8c72e61bb556109c7007d8ee8781a9f62071fa0c'
    };

    const qrCodeSrc = `/img/${selectedTab.toLowerCase()}.png`;

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
      }}>
        <div style={{
          maxWidth: '500px',
          width: '100%',
          padding: '20px'
        }}>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            {['USDC', 'BTC', 'ETH'].map((coin) => (
              <button
                key={coin}
                onClick={() => this.changeTab(coin)}
                style={{
                  padding: '10px 20px',
                  borderRadius: '5px',
                  border: 'none',
                  cursor: 'pointer',
                  margin: '0 5px',
                  backgroundColor: selectedTab === coin ? '#00b4f4' : '#ccc',
                  color: selectedTab === coin ? '#fff' : '#000'
                }}
              >
                {coin}
              </button>
            ))}
          </div>

          {isLoggedIn && (
            <div style={{ marginBottom: '20px' }}>
              <p style={{ margin: 0, fontSize: '16px' }}>
                <b>Current Balance:</b> {balance.toFixed(4)} {selectedTab}
              </p>
            </div>
          )}

          {isLoggedIn && (
            <React.Fragment>
              <div>
                <label style={{ display: 'block', fontSize: '14px', marginBottom: '5px' }}>
                  {selectedTab} Amount to donate (Off-Chain):
                </label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="number"
                    value={amount}
                    onChange={this.handleAmountChange}
                    style={{
                      flex: 1,
                      marginRight: '10px',
                      width: '100%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc'
                    }}
                    min="0"
                  />
                  <button
                    onClick={this.handleSend}
                    style={{
                      backgroundColor: '#00b4f4',
                      color: '#fff',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '16px'
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}

          <div style={{ marginTop: '15px' }}>
            <label style={{ display: 'block', fontSize: '14px', marginBottom: '5px' }}>
              {selectedTab} Address (On-Chain):
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={addresses[selectedTab]}
                readOnly
                style={{
                  flex: 1,
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  marginRight: '10px'
                }}
              />
              <button
                onClick={() => this.copyAddress(addresses[selectedTab])}
                style={{
                  backgroundColor: '#00b4f4',
                  color: '#fff',
                  borderRadius: '5px',
                  padding: '10px',

                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
        <div style={{
          marginLeft: '20px',
          display: 'flex',
          width: '300px',
          flexDirection: 'column',
          alignItems: 'center'
        }} className="charity-qr">
          <h5>{selectedTab} QR Code</h5>
          <img
            src={qrCodeSrc}
            alt={`${selectedTab} QR Code`}
            style={{
              width: '250px',
              border: '1px solid #ccc',
              objectFit: 'cover'
            }}
          />
        </div>
      </div>
    );
  }
}

class SunnyKidsEN extends Component {
  constructor(props) {
    super(props);
    this.scrollToDonateSection = this.scrollToDonateSection.bind(this);
  }

  scrollToDonateSection() {
    const donateSection = document.getElementById("donate");
    if (donateSection) {
      donateSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const autoScroll = queryParams.get("scrollToDonate");

    if (autoScroll === "true") {
      this.scrollToDonateSection();
    }
  }
  render() {

    const isLoggedIn = this.props.auth && this.props.auth.authenticated;
    const { accounts } = this.props;
    const balances = {
      usdc: getBalanceFor(accounts, 'USDC'),
      btc: getBalanceFor(accounts, 'BTC'),
      eth: getBalanceFor(accounts, 'ETH')
    };

    return (
      <div className="intro-text">
        <div className="article-full">
          <div className="transcard">
            <div className="">
              <div className="">
                <div className="row align-items-center text-left my-5 my-section">
                  <div className="col-12 col-md-5">
                    <h2 className="section-title text-left" style={headingStyle}>
                      BONEX for <br />
                      the Sunny Kids
                    </h2>
                    <p
                      className="section-description text-left"
                      style={paragraphStyle()}
                    >
                      You <b>help a child</b> with<br /> down syndrome.<br />
                      You <b>get discount</b> on our<br /> next commission.<br />
                    </p>
                    <div className="text-left">
                      <br />
                      <button
                        className="btn-link"
                        onClick={this.scrollToDonateSection}
                        style={{ color: '#000', backgroundColor: '#ffc543', fontWeight: 500 }}
                      >
                        Donate Crypto
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-7 text-center text-md-right" >
                    <img
                      src="/img/width_1194.webp"
                      alt="First section"
                      className="img-fluid charity-img-fluid"
                      style={imageStyle('300px')}
                    />
                  </div>
                </div>
                <div
                  className="row align-items-center text-left my-5 my-section"
                  style={rowSpacing}
                >
                  <div className="col-12 col-md-5">
                    <h2 className="section-title text-left" style={headingStyle}>
                      Donate Crypto &<br /> Get Discount
                    </h2>
                    <p
                      className="section-description text-left"
                      style={paragraphStyle(true)}
                    >
                      Your support can make a difference in the life of a child in need.
                    </p>

                    <div >At Bonex, we believe that small gestures can change the world. Together with the <b>"Sunny Kids 2024"</b> Foundation, we want to inspire more people to open their hearts and support children in need.<br /><br />
                      To encourage your kindness, Bonex will provide a discount ranging <b>from 30% to 100%</b> of our fee for your next order when you make a donation of at least 10 USDC.<br /><br />
                      <b>Why are we doing this?</b><br />
                      Our goal is simple—to motivate more and more people to donate. We believe that while you use our platform, not only can you benefit from our terms, but you can also become part of a bigger cause that touches human lives.<br /><br />
                      
                      <b>Donate. Inspire. Change.</b><br />
                      Bonex is here to support your kindness so we can build together a world filled with light and hope. 🌞
                      </div>

                    <div className="text-left" style={{ marginTop: '15px' }}>
                      <OverlappingButtons
                        yellowText="10 to 99 USDC"
                        blueText="- 30% on next transaction"
                      />
                    </div>
                    <div className="text-left" style={{ marginTop: '10px'  }}>
                      <OverlappingButtons
                        yellowText="100 to 999 USDC"
                        blueText="- 50% on next commission"
                        blueWidth="260px"
                      />
                    </div>
                    <div className="text-left" style={{ marginTop: '10px' }}>
                      <OverlappingButtons
                        yellowText="1000 USDC and more"
                        blueText="- 100% on next commission"
                        blueWidth="260px"
                      />
                    </div>
                    <div style={{ marginTop: '10px' }}>Equivalent amounts in ETH and BTC also qualify for the discount.</div>
                    <div style={{ marginTop: '10px', display: 'none' }}>Donations remain open even after January 31, 2025, and we will continue to offer special discounts based on the amount donated—because good deeds deserve support at all times. 
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-7 text-center"
                    style={{ marginTop: '80px', }}
                  >
                    <img
                      src="/img/width_1098.webp"
                      alt="First section"
                      className="img-fluid charity-img-w6"
                      style={imageStyle('150px', 30, 65)}
                    />
                  </div>
                  <div className="col-12 col-md-2" />
                </div>
                <div
                  className="row align-items-center text-left my-5 my-section"
                  style={rowSpacing}
                >
                  <div className="col-12 col-md-5">
                    <h2 className="section-title text-left" style={headingStyle}>
                      What will we achieve with <br /> the Foundation "Sunny Kids 2024"?
                    </h2>
                    <p
                      className="section-description text-left"
                      style={paragraphStyle()}
                    >
                      BONEX is proud to partner with Krisko and his foundation to support children with special needs and ensure a better future for them.<br /><br />

                      Your donation will contribute to:
                    </p>
                    <ul style={{ marginBottom: '40px' }}>
                      <li>Financing the treatment and the therapies of children.</li>
                      <li>Provision of scholarships and educational programs.</li>
                      <li>Organising initiatives for social integration.</li>
                    </ul>
                  </div>
                  <div
                    className="col-12 col-md-7 text-center charity-w6"
                    style={{ marginTop: '80px', marginLeft: '50px' }}
                  >
                    <img
                      src="/img/w6.png"
                      alt="First section"
                      className="img-fluid charity-img-w6 charity-img-w6a"
                      style={imageStyle('200px', 30, 165, 25)}
                    />
                    <br />
                    <div
                      className="text-center  " style={{ width: '300px', float: 'right' }} >
                      Our goal is to establish the largest center in the Balkans
                      for children with Down Syndrome and other health disabilities
                    </div>
                  </div>
                </div>
                <div className="row align-items-center text-left my-5 my-section" style={{ marginTop: '120px' }}>
                  <div className="col-12 col-md-5">
                    <div id="donate"></div>
                    <h2 className="section-title text-left" style={headingStyle}>
                      Donate Crypto
                    </h2>
                    <p
                      className="section-description text-left"
                    >
                      <CryptoPayment
                        isLoggedIn={isLoggedIn}
                        balances={balances}
                        account={this.props.account}
                        currency={this.props.currency}
                        sendCharityTransaction={this.props.sendCharityTransaction}
                      />
                    </p>
                  </div>
                </div>

                <div className="row align-items-center text-left my-5 my-section" style={{ marginTop: '120px' }}>
                  <div className="col-12 col-md-10" style={{ marginTop: '10px', fontSize: "14px" }}>
                    We offer two methods for donations – On-Chain and Off-Chain – to ensure convenience and efficiency for our users. Here’s how they work:<br /><br />

                    <b>On-Chain Donations</b>
                    With On-Chain donations, cryptocurrency is sent directly to the public address of the charity organization. These transactions are recorded on the blockchain and can be verified by anyone, although network fees apply.
                    <br /><br />
                    <b>Off-Chain Donations</b>
                    Off-Chain donations utilize the platform's internal balance. This is a faster and more convenient method that avoids network fees. Transactions are processed internally within BoneX and are fully traceable on the platform. Clients with an internal balance also receive a discount of 30% to 100% on fees for future transactions.
                    <br /><br />
                    Both methods are reliable and transparent within their respective systems – the blockchain for On-Chain donations and the BoneX platform for Off-Chain donations. Choose the one that best suits your needs.
                  </div>
                </div>
                <h2 className="section-title text-left" style={headingStyle}>
                  Donation History
                </h2>

                <div style={{ marginTop: '40px' }}>
                  <TransactionsTablePublicNew />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    accounts: state.accounts,
    account: state.account,
    currency: state.currency,
    unseenCount: state.messages.unseenCount,
  };
}

export default connect(
  mapStateToProps,
  { sendCharityTransaction, fetchUnseenMessages }
)(SunnyKidsEN);