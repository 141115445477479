import _ from "lodash";
import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";

class CommissionPopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activeLanguage, onClosePopupClick } = this.props;

    const translations = {
      en: {
        videoUrl: "https://www.youtube.com/embed/JyvQkuV4Zes?autoplay=1&mute=1",
        videoTitle: "BONEX for Sunny Kids Foundation 2024",
        learnMoreText: "Learn more",
      },
      bg: {
        videoUrl: "https://www.youtube.com/embed/iQ09XISOy1o?autoplay=1&mute=1",
        videoTitle: "BONEX за Фондация “Слънчевите Деца” 2024",
        learnMoreText: "Научи повече",
      },
    };

    const currentLang = activeLanguage.code === "bg" ? "bg" : "en";
    const { videoUrl, videoTitle, learnMoreText } = translations[currentLang];

    return (
      <div className="commission-modal-wrapper" onClick={onClosePopupClick}>
        <div className="commission-modal">
          <div className="commission-modal-card">
            <div className="modal-card-text">
              <button type="button" onClick={onClosePopupClick}>x</button>

              <div>
                <iframe
                  key={videoUrl}
                  width="332"
                  height="591"
                  src={videoUrl}
                  title={videoTitle}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
                <div style={{ paddingBottom: "25px" }}>
                  <a className="btn-link-box orange-btn" href="/charity-sunnykids">
                    {learnMoreText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(CommissionPopup);
