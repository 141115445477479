import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Translate } from "react-localize-redux";
import { fetchUser, activateGameBonus } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';

class GiveawayPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      send: false,
      code: '',
      error: ''
    };
  }

  async activateGameBonus(e) {
    e.preventDefault();

    let result = await this.props.activateGameBonus(this.state.code);
    if (result.success) {
      this.setState({ send: true });
    } else {
      this.setState({ error: result.error })
    }
  }

  head() {
    return (
      <Helmet>
        <title>{`Giveaway - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Giveaway - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  render() {
    if (this.state.send) {
      return (
        <div>
          {this.head()}
          <div className="intro-box">
            <div className="card-cover card-cover-2 mining-cover mt-0">
              <img className="w-100" src="/img/messages/message-1-cover.png" alt="" />
            </div>
            <div className="article-full">
              <div className="transcard text-center">
                <h2 className="">
                  <span>
                    Рожденикът черпи с 25 USDC всеки месец
                  </span>
                </h2>
                <p>
                  Успешно използва своят специален код за получаване на 25 USDC от нашата игра.
                  <hr />
                  Successfully used your special code to get 25 USDC from our game.
                  <hr />
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.head()}
          <div className="intro-box">
            <div className="card-cover card-cover-2 mining-cover mt-0">
              <img className="w-100" src="/img/messages/message-1-cover.png" alt="" />
            </div>
            <div className="article-full">
              <div className="transcard text-center">
                <h2 className="">
                  <span>
                    Giveaway
                  </span>
                </h2>
                <div className="transcard">
                  <div className="row">
                    <div className="col-sm-12 col-lg-6 col-lg-offset-3">
                      <form method="post" onSubmit={(e) => this.activateGameBonus(e)}>
                        <div className="form-group">
                          <div className="label-animated">
                            <input type="text" name="code" value={this.state.code} className='input-primary' onChange={(e) => this.setState({ code: e.target.value })} required />
                            <label>Code</label>
                          </div>
                        </div>
                        <label className="custom-check">
                          <input type="checkbox" id="agree_giveaway" required />
                          <i></i>
                          Декларирам, че съм запознат/-а с условията на <a href="https://bonex.org/giveaway-terms" title="">официалните правила за провеждане на Играта</a> и заявявам, че ги приемам и се съгласявам с тях. <br />
                          I declare that I am familiar with the terms of the <a href="https://bonex.org/giveaway-terms" title="">official rules for conducting the Game</a> and declare that I accept and agree with them.
                        </label>
                        <div className="mb-more">
                          <button className="link-btn btn-small mr-2 ml-2" onClick={() => this.setState({ code: '' })}><Translate id="cancel" /></button>
                          <button className="link-btn btn-small mr-2 ml-2"><Translate id="submit" /></button>
                          {(this.state.error != '') ? (
                            <div className="mt-5">
                              <p className="text-error">{this.state.error}</p>
                            </div>
                          ) : ""}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};




function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default {
  component: connect(mapStateToProps, { activateGameBonus })(
    requireAuth(GiveawayPage)
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser())
}
