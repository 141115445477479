import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class MarginPlatformRO extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/margin/BANNER.png" alt="" />
          <h1 style={{ marginTop: '-30px' }}><Translate id="marginPlatfrom" /></h1>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center animate mb-more"></h2>
            <div className="benefits-list">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/scale-icon.png" alt="" />
                    </figure>
                    <h3>Tranzacționare</h3>
                    <ul className="text-left">
                      <li>
                        Procese de tranzacționare simplificate pentru o execuție facilă, gestionarea pozițiilor și monitorizarea portofoliului.
                      </li>
                      <li>
                        Suport dedicat din partea echipei noastre de experți pentru a vă asista cu întrebările și problemele dumneavoastră.
                      </li>
                      <li>
                        O gamă de bonusuri și stimulente pentru a vă îmbunătăți experiența de tranzacționare.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/speed-icon.png" alt="" />
                    </figure>
                    <h3>Eficiență</h3>
                    <ul className="text-left">
                      <li>
                        Platforma Bonex Margin utilizează măsuri de securitate de ultimă generație pentru liniștea dumneavoastră.
                      </li>
                      <li>
                        Angajată să ofere o experiență de tranzacționare eficientă din punct de vedere fiscal și cost-eficientă.
                      </li>
                      <li>
                        Împuternicirea programelor de afiliere și brokeraj pentru utilizatorii noștri.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/currency-icon.png" alt="" />
                    </figure>
                    <h3>Posibilități</h3>
                    <ul className="text-left">
                      <li>
                        Experimentează tehnologia de tranzacționare de ultimă oră cu Platforma Bonex Margin, potrivită atât pentru începători, cât și pentru traderi experimentați, oferind instrumente puternice pentru optimizarea strategiilor tale.
                      </li>
                      <li>
                        Accesează o selecție diversificată de perechi de token-uri pe platforma noastră, permițându-ți să explorezi diferite oportunități de tranzacționare și să-ți diversifici portofoliul.
                      </li>
                      <li>
                        Îmbunătățește-ți capacitățile de tranzacționare cu tranzacționarea pe margine, descoperind noi posibilități pentru traderii care doresc să-și maximizeze strategiile.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MarginPlatformRO;
