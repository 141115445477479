import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class OpenBankingRO extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/open_banking/Online_Banking.png" alt="" />
        </div>
        <div className="article-full">
          <div className="transcard">

            <div className="mining-text pt-0">
              <h2 className="text-center">
                NOU DE LA BONEX<br />
                <span>GESTIONEAZĂ TOATE FINANȚELE<br /> DINTR-UN SINGUR LOC - 24/7</span>
              </h2>
              <p>
                BoneX se gândește la confortul și libertatea ta, iar pentru ca tu să te poți concentra pe lucrurile la care ești cu adevărat bun și să crești și mai rapid, îți aducem o soluție unică pentru gestionarea 24/7 a tuturor fondurilor tale – rapid, ușor și complet online.
              </p>
              <p>
                <strong>Monitorizează și gestionează fluxurile tale de numerar de oriunde din lume, oricând!</strong>
              </p>
              <p>
                Acum poți urmări și gestiona cu ușurință și rapiditate fondurile tale din toate conturile bancare și portofelele crypto, din contul tău personal BoneX, cu viteză fulger și cel mai înalt nivel de securitate garantat de partenerul nostru tehnologic licențiat, Aris Solutions.
              </p>
              <div className="mb-more"></div>
              <br />
              <br />
              <div className="benefits-list no-margin-sides">
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/fast.png" alt="" />
                        <span></span>
                      </div>
                      <h3>CONVENIENȚĂ ȘI VITEZĂ</h3>
                      <p>Efectuează tranzacții instantanee din toate conturile tale bancare și portofelele crypto BoneX</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/control.png" alt="" />
                        <span></span>
                      </div>
                      <h3>CONTROL COMPLET</h3>
                      <p>Urmărește-ți soldul și istoricul tranzacțiilor în detaliu</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="benefit-entry-box">
                      <div className="figure">
                        <img src="/img/open_banking/security.png" alt="" />
                        <span></span>
                      </div>
                      <h3>NIVEL ÎNALT DE SECURITATE</h3>
                      <p>Păstrează-ți conturile și datele private cu un sistem criptat ce oferă protecție pe mai multe niveluri</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-center"><strong>Optimizează-ți gestionarea financiară și crește și mai rapid cu BoneX!</strong></p>
              <div className="text-center">
                <br />
                <a className="btn-link" to={'bank'}>ÎNCEPE ACUM</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OpenBankingRO;
