import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import AssetRates from "../assets/AssetRates";
import Loading from "../Loading";
import {
  fetchTransaction,
  sendTransaction,
  clearTransaction,
  setError,
  fetchNetworkAddress
} from "../../actions";
import { formatMoney, formatCrypto } from "../../utilities/Format";
import Account3New from "./Account3New";

const paymentMethods = {
  account: "address"
};

const receivingMethods = {
  account: "account"
};

const SELECTED_NETWORK = {
  USDC_ERC20: "USDC-ERC20",

}

class AccountDepositFormNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0.0,
      price: 0.0,
      total: 0.0,
      rate: 0.0,
      copied: false,
      paymentMethod: "address",
      receivingMethod: "account",
      receivingMethodValid: true,
      formErrors: {
        amount: "",
        price: "",
        paymentMethod: "",
        receiver: "",
        receivingMethod: ""
      },
      // networkInputValue: "",
      networkAdress: "",
    };

    this.onCalculatorChange = this.onCalculatorChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  onNetworkChange(e, accountType) {
    this.props.fetchNetworkAddress(accountType, e.currentTarget.value)
  }

  onCalculatorChange(amount, price, field, rate) {
    this.setState({ amount, price, total: price, rate });
  }

  onReceivingMethodChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ receivingMethod: value });
  }

  onSubmit(event) {
    event.preventDefault();
    this.props.sendTransaction({
      ...this.state,
      account: this.props.account,
      type: "DEPOSIT",
      currency: this.props.currency
    });
  }

  componentDidMount() {
    if (this.props.account.type && this.props.account.network) {
      this.props.fetchNetworkAddress(this.props.account.type, this.props.account.network);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.account.identificator !== prevProps.account.identificator) {
      this.props.fetchNetworkAddress(this.props.account.type, this.props.account.network);
    }
  }

  componentWillUnmount() {
    this.props.clearTransaction();
  }

  copyToClipboard(e) {
    this.cryptoAddress.select();

    setTimeout(() => {
      document.execCommand("copy");
      this.setState({
        copied: true
      });
    }, 500);
  }

  render() {
    const additionalNetworksArray = this.props.additionalNetworks !== null && this.props.additionalNetworks.length > 0 ? this.props.additionalNetworks.split(",") : []

    if (this.props.auth.authenticated && this.props.user.totp == 'none'  && this.props.auth.totpRequired) {
      this.props.setError('2FA Authentication Required');
      return (
        <Redirect to="/settings" />
      );
    }

    if (!_.isEmpty(this.props.account)) {
      // const identificatorPair = this.props.account.identificator.split(":");

      return (
        <div className="account-action-deposit-form-wrapper">
          <div className="row deposit-center-box" style={{ width: "100%", margin: "auto" }}>
            <div className="">
              {/* <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                <h3>
                  <Translate id="depositTexts.pt1" /> {this.props.account.title}
                  !
                </h3>
              </header> */}
              <Account3New
                account={this.props.account}
                rates={this.props.rates}
                currency={this.props.currency}
              />
            </div>
            <div className="col-xs-12">
              <form className="payment-field-wrapper">
                <div className="col-md-12 label-animated">
                  <label htmlFor="network"
                    style={{
                      color: "white",
                      top: "-4px",
                      width: "82%",
                      textAlign: "left",
                    }}
                  >
                    Network:
                  </label>
                  <select
                    className="payment-field"
                    id="network"
                    name="network"
                    style={{
                      color: "white",
                      width: "100%"
                    }}
                    onChange={(e) => this.onNetworkChange(e, this.props.account.type)}
                  >
                    <option value={this.props.account.network} style={{ color: "black" }}>{this.props.account.network}</option>
                    {this.props.additionalNetworks !== null
                      ?
                      additionalNetworksArray.map((additionalNetwork) =>
                        <option
                          key={additionalNetwork}
                          value={additionalNetwork}
                          style={{ color: "black" }}>
                          {additionalNetwork}
                        </option>
                      )
                      :
                      ""
                    }
                  </select>
                </div>
                <div className="col-xs-12 label-animated">
                  <input
                    className="payment-field"
                    type="text"
                    value={this.props.networkAddress.address || ""}
                    disabled
                    style={{ width: "100%" }}
                  // style={{ color: "black" }}
                  >
                  </input>
                  {/* <i
                    className="fa fa-copy"
                    style={{ paddingRight: "15px" }}
                    onDoubleClick={this.copyToClipboard}
                    title="Copy"
                  ></i> */}
                </div>
              </form>
              <div className="col-xs-12 success-msg-box">
                <div className="deposit-info-box-new">
                  <p><Translate id="depositTexts.min-deposit" />:</p>
                  <p>{this.props.minDeposit}</p>
                </div>
                <div className="deposit-info-box-new">
                  <p><Translate id="depositTexts.number-confirmations" />:</p>
                  <p><strong>{this.props.numberConfirmations}</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps({ user, auth, transaction, currency, networkAddress, account, rates }) {
  return { user, auth, transaction, currency, networkAddress, account, rates };
}

export default connect(
  mapStateToProps,
  { fetchTransaction, sendTransaction, fetchNetworkAddress, clearTransaction, setError }
)(AccountDepositFormNew);
