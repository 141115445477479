import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import { fetchUnseenMessages } from '../../actions';

class UserSidebarNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unseenCount: 0
    };
  }


  async componentDidMount() {
    let unseen = await this.props.fetchUnseenMessages();
    if (unseen && unseen.result) {
      this.setState({ unseenCount: unseen.result.length });
    }
  }

  render() {
    return (
      <div className="">
        <div style={{ textAlign: "center", paddingBottom: '50px' }}>

          <div className="container index-partner-section" style={{ textAlign: "center" }}>
            <a href="/charity-sunnykids">
              {this.props.activeLanguage.code === "bg"
                ? (<img src="/img/sk-banner.png" alt="sunnykids" />)
                : (<img src="/img/sk-banner-en.png" alt="sunnykids" />)}
            </a>
          </div>

        </div>
        <div className="navigation-horizontal-menu-wrapper">
          <div className="gradient-box">
            <div className="navigation-horizontal-menu">
              <div className="">
                <span></span>
                <ul className="nav row">
                  <li className={` navigation-link ${this.props.current == "profile" ? "active" : ""}`}>
                    <Link to="/profile">
                      <span className="icon-holder">
                        <i className="far fa-user-circle"></i>
                      </span> {` `}
                      <Translate id="profile" />
                    </Link>
                  </li>
                  <li
                    className={` navigation-link ${this.props.current == "accounts" ? "active" : ""}`}
                  >
                    <Link to="/accounts">
                      <span className="icon-holder">
                        <i className="far fa-wallet"></i> {` `}
                      </span>
                      <Translate id="balance" />
                    </Link>
                  </li>
                  {this.props.user.verified == 1 ? (
                    // <li
                    //   className={this.props.current == "card" ? "active" : ""}
                    // >
                    //   <Link to="/card">
                    //     <span className="icon-holder">
                    //       <i className="fas fa-angle-right"></i>
                    //     </span>
                    //     <Translate id="card" />
                    //   </Link>
                    // </li>
                    <li></li>
                  ) : (
                    <li></li>
                  )}
                  <li
                    className={
                      ` navigation-link ${this.props.current == "orders" ? "active" : ""}`
                    }
                  >
                    <Link to="/orders">
                      <span className="icon-holder">
                        <i className="far fa-credit-card"></i> {` `}
                      </span>
                      <Translate id="orders" />
                    </Link>
                  </li>

                  <li
                    className={`navigation-link ${this.props.current == "transactions" ? "active" : ""}`}
                  >
                    <Link to="/transactions">
                      <span className="icon-holder">
                        <i className="fas fa-exchange-alt"></i> {` `}
                      </span>
                      <Translate id="transactions" />
                    </Link>
                  </li>
                  <li
                    className={`navigation-link ${this.props.current == "settings" ? "active" : ""}`}
                  >
                    <Link to="/settings">
                      <span className="icon-holder">
                        <i className="far fa-shield"></i> {` `}
                      </span>
                      <Translate id="settings" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default connect(mapStateToProps, { fetchUnseenMessages })(withLocalize(UserSidebarNew));
