import React, { useEffect, useState } from 'react';

const SafeHydration = ({ children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null; // Prevents rendering until hydration is complete
  return <React.Fragment>{children}</React.Fragment>;
};

export default SafeHydration;
