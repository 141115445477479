import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import { CarsDetailCarousel } from './CarsDetailCarousel';
import CarsRentModal from './CarsRentModal';

export const CarsItem = ({ item, rates, currency, lang, doCarRequest }) => {
  const [submit, setSubmit] = useState(false);
  if (!item) return <div></div>;

  const openModal = () => {
    setSubmit(true);
  }

  const handleHideModal = async () => { setSubmit(false); };

  const handleRequest = async (formData) => {
    let request = await doCarRequest({ ...formData, provider: item.provider, car_id: item.id, lang: lang });
  }

  const renderForm = () => {
    return <CarsRentModal handleHideModal={handleHideModal.bind(this)} onRequest={handleRequest.bind(this)} />;
  }

  let btc, eth, usdc;
  let btcRate, ethRate, usdcRate;
  if (rates) {
    _.map(rates, rate => {
      if (rate.type == 'BTC') {
        btc = rate;
      }

      if (rate.type == 'ETH') {
        eth = rate;
      }

      if (rate.type == "USDC") {
        usdc = rate;
      }
    });

    if (btc) {
      btcRate = btc.rate;
    }

    if (eth) {
      ethRate = eth.rate;
    }

    if (usdc) {
      usdcRate = usdc.rate;
    }
  }

  return (
    <React.Fragment>
      {submit ? (
        renderForm()
      ) : ""}
      <section className="car-detail">
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            {item.images && item.images.split(',').length > 0 ? (
              <CarsDetailCarousel slides={[item.image, ...item.images.split(',')]} />
            ) : (
            <CarsDetailCarousel slides={[item.image]} />
            )}
          </div>
          <div className="col-sm-12 col-lg-4">
            <header>
              <h1 className="page-title-box">
                {item.maker} {item.model}
              </h1>
            </header>
            <div className="text">
              <p>
                {item.description}
              </p>
              <ul>
                <li>Make: <strong>{item.maker}</strong></li>
                <li>Model Year: <strong>{item.year}</strong></li>
                <li>Horsepower: <strong>{item.horse_power}</strong></li>
                <li>Seater: <strong>{item.seats}</strong></li>
                {item.additional &&
                  <li>Additional: <strong>{item.additional}</strong></li>
                }
              </ul>
              <div className="rent-box">
                <div className="row">
                  <div className="col-sm-12 col-lg-5">
                    <h5>Rental Cost</h5>
                  </div>
                  <div className="col-sm-12 col-lg-7 text-lg-right">
                    <p>
                      {item.daily != 0 ? (
                        <span>Price for 1 day</span>
                      ) : ""}
                      <p>
                        {item.daily != 0 ? (
                          <React.Fragment>
                            <small><img src={`/img/assets/btc.png`} style={{ width: '20px' }} /> {parseFloat((item.daily * currency.rate) / btcRate).toFixed(4)} BTC</small>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <small><img src={`/img/assets/btc.png`} style={{ width: '20px' }} /> {parseFloat((item.price * currency.rate) / btcRate).toFixed(4)} BTC</small>
                          </React.Fragment>
                        )}
                      </p>
                      <p>
                        {item.daily != 0 ? (
                          <React.Fragment>
                            <small><img src={`/img/assets/usdc.svg`} style={{ width: '20px' }} /> {parseFloat((item.daily * currency.rate)).toFixed(2)} USDC</small>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <small><img src={`/img/assets/usdc.svg`} style={{ width: '20px' }} /> {parseFloat((item.price * currency.rate)).toFixed(2)} USDC</small>
                          </React.Fragment>
                        )}
                      </p>
                    </p>
                  </div>
                </div>
                <button type="button" className="btn-link-box fill" onClick={() => openModal()}>Reserve now</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tech-data col-5">
        <header className="section-title">
          <h2>Rental details</h2>
        </header>
        <div className="tech-data-entry">
          <h3>Kilometers Included</h3>
          <p><strong>{item.additional}</strong></p>
        </div>
        <div className="tech-data-entry">
          <h3>Deposit</h3>
          <p>0,25 BTC</p>
        </div>
        <div className="tech-data-entry">
          <h3>Price per day</h3>
          <p>{item.daily}</p>
        </div>
        <div className="tech-data-entry">
          <h3>Shipping</h3>
          <p>Free Delivery to<br /> Hotels/Airport</p>
        </div>
        <div className="tech-data-entry">
          <h3>Support</h3>
          <p>24/7 Support</p>
        </div>
      </section>
      <section className="tips-box">
        <header className="section-title big-title">
          <h2>
            HOW do
            <span> I Rent?</span>
          </h2>
        </header>
        <div className="row">
          <div className="col-sm-12 col-sm-6 col-lg-4 mb-5">
            <div className="tips-data-entry">
              <figure>
                <img src="/img/icons/book-icon.png" alt="" />
              </figure>
              <h3>Book Online</h3>
              <p>Click Book now and Fill your Contact Details.</p>
            </div>
          </div>
          <div className="col-sm-12 col-sm-6 col-lg-4 mb-5">
            <div className="tips-data-entry">
              <figure>
                <img src="/img/icons/details-icon.png" alt="" />
              </figure>
              <h3>Send Details</h3>
              <p>Send your Passport/Driving license​</p>
            </div>
          </div>
          <div className="col-sm-12 col-sm-6 col-lg-4 mb-5">
            <div className="tips-data-entry">
              <figure>
                <img src="/img/icons/receive-pin-icon.png" alt="" />
              </figure>
              <h3>Receive the car</h3>
              <p>We will deliver the car to your location.​</p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  );
}
