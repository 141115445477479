import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class BuyPropertyRO extends Component {
  render() {
    return (
        <div className="intro-text">
            <div className="card-cover card-cover-2">
                <img src="/img/covers/Bonex_Estate.PNG" alt="" />
                <h1 style={{ marginTop: '-30px' }}><Translate id="buyPropertyCrypto" /></h1>
            </div>
            <div className="article-full">
                <div className="transcard text-center pt-5">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 mb-3">
                            <a className="btn-link purple-gradient has-arrow has-big-icon w-100 disabled" href="" title="">
                                <i className="far fa-home-alt"></i>
                                Vezi<br />oferte
                            </a>
                        </div>
                        <div className="col-sm-12 col-lg-6 mb-3">
                            <Link className="btn-link purple-gradient has-arrow has-big-icon w-100" to="buy-property/add-property">
                                <i className="fal fa-plus-circle"></i>
                                <Translate id="addProperty" />
                            </Link>
                        </div>
                    </div>
                    <h2 className="text-center animate">DEVINO PARTENER</h2>
                    <p className="font-26">Vindeți proprietăți imobiliare?</p>
                    <div className="lines-box text-center">
                      <p className="font-26">
                        Devino partener BoneX și extinde-ți oportunitățile de vânzare. Atinge noi clienți din universul crypto, primește plăți instantanee și sigure în BGN, Euro, USD sau Crypto pentru proprietățile tale și gestionează-ți finanțele cu ușurință în moneda preferată, de oriunde din lume. BoneX se va ocupa de tot, astfel încât să te poți concentra doar pe lucrurile la care ești bun!
                      </p>
                    </div>
                    <div className="lines-box">
                        <div className="row mb-5">
                            <div className="col-sm-12 col-lg-6 col-lg-offset-3 mb-5">
                                <a className="btn-link blue-gradient has-arrow has-big-icon" href="mailto:property@bonex.org" title="">
                                    <i className="fal fa-phone-alt"></i>
                                   CONTACTEAZĂ-NE
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="article-full">
                <div className="transcard text-center">
                    <h2 className="text-center animate">BONEX ÎȚI OFERĂ O OPORTUNITATE UNICĂ</h2>
                    <p className="font-26"><i><em>Folosește-ți criptomonedele pentru a cumpăra orice imobil de pe piață.</em></i></p>
                    <p className="font-26"><i><em>
                      Folosește portofelul BoneX și cumpără-ți noua locuință sau proprietate de investiție în BTC, ETH, USDC, BCH, RVN, UNI, LINK, AAVE. BoneX are experiența și know-how-ul necesar pentru a transforma criptomoneda aleasă de tine în monede clasice și a o trimite direct vânzătorului locuinței dorite.
                    </em></i></p>
                </div>
                <div className="property-logo-buy text-center">
                    <div className="logo-entry">
                        <img src="/img/assets/btc.png" alt="" />
                    </div>
                    <div className="logo-entry">
                        <img src="/img/assets/eth.png" alt="" />
                    </div>
                    <div className="logo-entry">
                        <img src="/img/assets/usdc.svg" alt="" />
                    </div>
                    <div className="logo-entry">
                        <img src="/img/assets/bch.png" alt="" />
                    </div>
                    <div className="logo-entry">
                        <img src="/img/assets/rvn.png" alt="" />
                    </div>
                    <div className="logo-entry">
                        <img src="/img/assets/uni.png" alt="" />
                    </div>
                    <div className="logo-entry">
                        <img src="/img/assets/link.png" alt="" />
                    </div>
                    <div className="logo-entry">
                        <img src="/img/assets/aave.png" alt="" />
                    </div>
                </div>
                <div className="transcard text-center">
                    <p className="font-26"><i><em>
                      Putem aranja o întâlnire la biroul nostru cu echipa noastră juridică, care îți va explica ție și vânzătorului proprietății planul de acțiune pentru o astfel de tranzacție, în cel mai clar mod posibil. Suntem primul exchange crypto care oferă acest serviciu și avem deja experiență. Profită de această oportunitate!
                    </em></i></p>
                    <a className="btn-link" href="mailto:property@bonex.org">CONTACTEAZĂ-NE</a>
                    <br /><br /><br /><br />
                </div>
            </div>
        </div>
    )
  }
}

export default BuyPropertyRO;
