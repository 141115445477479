import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

const AccountActionsNew2 = (props) => {
    if (!_.isEmpty(props.account)) {
        return (
            <div
                className="account-action-buttons-new-wrapper"
            >
                <div className='button-group-wrapper'>
                    {(props.account.type == "USDC" && props.currency.type == "USDC") ? "" : (
                        <div className="account-action-button-new">
                            {!props.trade_disabled && (
                                <Link to={`/accounts/${props.account.type}/buy`} title="" className="btn">
                                    <div className='account-action-button-icon buy-sell-buttons'>
                                        <i className="fal fa-plus"></i>
                                    </div>
                                    <Translate id="buy" />
                                </Link>
                            )}
                        </div>
                    )}
                    {(props.account.type == "USDC" && props.currency.type == "USDC") ? "" : (
                        <div className="account-action-button-new">
                            <Link to={`/accounts/${props.account.type}/sell`} title="" className="btn">
                                <div className='account-action-button-icon buy-sell-buttons'>
                                    <i className="fal fa-minus"></i>
                                </div>
                                <Translate id="sell" />
                            </Link>
                        </div>
                    )}
                </div>
                <div className='button-group-wrapper'>
                    {!props.trade_disabled && (
                        <div className="account-action-button-new">
                            <Link to={`/accounts/${props.account.type}/deposit`} title="" className="btn">
                                <div className='account-action-button-icon deposit-withdraw-button'>
                                    <i className="fad fa-arrow-to-bottom  account-action-icon deposit-withdraw0button"></i>
                                </div>
                                <Translate id="deposit" />
                            </Link>
                        </div>
                    )}
                    <div className="account-action-button-new">
                        <Link to={`/accounts/${props.account.type}/withdraw`} title="" className="btn">
                            <div className='account-action-button-icon deposit-withdraw-button'>
                                <i className="fad fa-arrow-to-top "></i>
                            </div>
                            <Translate id="withdraw" />
                        </Link>
                    </div>
                </div>

                {(props.account.type == "USDC" && props.currency.type == "USDC") ? (
                    <React.Fragment>
                        <div className="col-lg-6 col-md-6 col-sm-12">&nbsp;</div>
                        <div className="col-lg-6 col-md-6 col-sm-12">&nbsp;</div>
                    </React.Fragment>
                ) : ""}
            </div >
        );
    } else {
        return <div />
    }
};

export default AccountActionsNew2;
