import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class CryptoAccountingGR extends Component {
  render() {
    return (
      <div className="intro-text">
        <div className="card-cover card-cover-2">
          <img src="/img/crypto-cover-1.png" alt="" />
          <h1><Translate id="cryptoAccounting" /></h1>
        </div>
        <div className="article-full">
          <div className="transcard">
            <h2 className="text-center animate mb-more">
              <span>Η ΔΙΑΔΙΚΑΣΙΑ ΚΑΙ Η ΚΑΛΗ ΟΡΓΑΝΩΣΗ ΤΗΣ ΕΡΓΑΣΙΑΣ ΕΙΝΑΙ ΣΤΟΙΧΕΙΑ ΚΛΕΙΔΙ ΓΙΑ ΚΑΘΕ ΕΠΙΧΕΙΡΗΜΑΤΙΚΗ ΔΡΑΣΤΗΡΙΟΤΗΤΑ, ΚΑΘΩΣ ΕΠΙΣΗΣ Η ΕΜΠΙΣΤΗ ΟΜΑΔΑ ΕΠΑΓΓΕΛΜΑΤΙΩΝ. </span>
            </h2>
            <div className="benefits-list">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/scale-icon.png" alt="" />
                    </figure>
                    <h3>ΤΑ ΩΦΕΛΗΜΑΤΑ ΑΠΟ ΤΗ ΛΟΓΙΣΤΙΚΗ ΤΩΝ ΚΡΥΠΤΟΝΟΜΙΣΜΑΤΩΝ</h3>
                    <ul className="text-left">
                      <li>Φροντίδες από επαγγελματική ομάδα για την επιχειρηματική σου δραστηριότητα</li>
                      <li>Εμείς γνωρίζουμε τον κόσμο των κρυπτονομισμάτων, με εμάς θα είναι πιο εύκολα</li>
                      <li>Η δραστηριότητα και τα εισοδήματά σου είναι σε τάξη και σωστά από νομική άποψη</li>
                      <li>Μπορείς να σκέφτεσαι ήσυχα πως να αναπτύσσεσαι</li>
                      <li>Εμείς είμαστε ψηφιακοί, επικοινωνούμε εκεί, όπου και εσύ, χρησιμοποιούμε ψηφιακά έγγραφα και θα δημιουργήσουμε οργάνωση προσαρμοσμένη στην δική σου επιχειρηματική δραστηριότητα</li>
                      <li>Συγκεντρώνεσαι στο κρύπτο, ενώ τα έγγραφα είναι για εμάς</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="benefit-entry-box">
                    <figure>
                      <img src="/img/promo/card-icon.png" alt="" />
                    </figure>
                    <h3>ΓΙΑ ΟΛΙΚΗ ONLINE ΕΞΥΠΗΡΕΤΗΣΗ</h3>
                    <ul className="text-left">
                      <li>Γρήγορη και εύκολη επικοινωνία online, που εξοικονομεί χρόνο </li>
                      <li>Ισχυρή φορολογική και λογιστική πραγματογνωμοσύνη </li>
                      <li>Βελτιστοποίηση των λειτουργικών διαδικασιών και του προϋπολογισμού για λογιστική υπηρεσία</li>
                      <li>Ευέλικτη υπηρεσία ανάλογα με τους σκοπούς και τις προτεραιότητες του πελάτη</li>
                      <li>Επαγγελματίες λογιστές με μακροχρόνια εμπειρία</li>
                      <li>Πληρώνεις μόνο αυτό, που πραγματικά χρησιμοποιείς</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-center bottom-text">
              <strong>
                Για περισσότερες πληροφορίες<br /> μπορείτε ναγράψετε σε {" "}
                <a
                  href="mailto:cryptoaccounting@bonex.org"
                  title=""
                >cryptoaccounting@bonex.org</a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default CryptoAccountingGR;
