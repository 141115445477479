import _ from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { CarsListItem } from './CarsListItem';

export const CarsList = ({ items, type, rates, currency, isMarginRoute }) => {
  return (
    <React.Fragment>
      <div className="cars-list mt-5">
        <div className="row">
          {_.map(items, item => (
            <CarsListItem key={item.id} type={type} item={item} rates={rates} currency={currency} isMarginRoute={isMarginRoute} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};
