import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class DefiGR extends Component {
    render() {
        return (
            <div className="intro-text">
                <div className="card-cover card-cover-2">
                    <img src="/img/defi/Defibot_EN.png" alt="" />
                    <a title=""><img src="/img/promo/arrow-card-down.png" alt="" /></a>
                </div>
                <div className="article-full">
                    <div className="transcard text-center">
                        <h2><span className="purple-text">AI</span> CRYPTO TRADING <span className="purple-text">BOT</span><br />YOU CAN ACTUALLY TRUST</h2>
                        <p><em className="purple-text">AI DeFiBot is a product that offers what we all thrive for, And that is true freedom, the reason we fell in love with crypto so many years ago.
                        </em></p>
                        <br />
                        <h4><strong className="purple-text">Decentralised, pure and without interference.</strong></h4>
                        <br />
                        <p className="blue-text">DeFiBot allows you to hold your money in crypto currency without locking it or sending your funds to a third party.
                        </p>
                        <br /><br />
                    </div>
                </div>
                <div className="article-full visible-box article-mrg-btn">
                    <div className="transcard text-center">
                        <img className="defi-logo-content" src="/img/defi/defi-bot-logo.png" alt="" />
                        <h2>We created Invictus DeFiBot because we are true believers in financial freedom.</h2>
                        <h4><strong>Our AI algorithm can trade your BTC ETH or USDC from your own account remotely via API in numerous crypto exchanges like Coinbase Binance Bybit and Bitmex.
                            We have been developing and trading with Invictus since 2017 and we feel confident to share this product to the world.
                        </strong></h4>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>24/7 WORLDWIDE DATA ANALYSIS</h3>
                                    <p>The AI never uses more than x2 leverage and never enters position with more than 30% of your wallet’s capital.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>FREEDOM AND SECURITY FOR YOUR CRYPTO ASSETS </h3>
                                    <p>Statistically we have maintained a steady profit of 28% yearly with an extremely conservative draw dawn of up to 4%..</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="dot-text orange">
                                    <h3>TRADING WITH NO EMOTION AND FATIGUE</h3>
                                    <p>Risk control is the most important aspect of the AI and protecting client funds is of outmost importance.</p>
                                </div>
                            </div>
                        </div>
                        <hr className="purple-line" />
                        <p className="text-center purple-text">
                            <em>
                                DISCLAIMER
                                <br /><br />
                                The services provided by DeFiBot are not performed by BoneX and We are not responsible for the actions of third parties, as well as for potentially negative results of using the services of third parties. No communication or information provided to You by Us is intended as, and shall not be construed as, financial advice or recommendation. The sample strategies provided by BoneX for Your reference or use are also not to be construed as financial advice. The current  announcement constitutes a warning that any operations with virtual currencies carry financial risks and BoneX does not provide any guarantee of profits or protection against loss.
                                <br /><br />
                                The information and results provided from using DeFiBot have not been verified by BoneX. Your decision to become a user of DeFiBot services is based on your own judgment and experience in trading virtual assets. By using the link provided that refers to the DeFiBot site, you exit the BoneX site and Our Terms and Conditions and related documents do not apply to your relationship with the service provider operating the DeFiBot site.When using the services of third parties, you should familiarize yourself with the Terms and Conditions and the Privacy Policy of the providers.
                                <br /><br />
                                <a href="">I Confirm {">"}</a>
                            </em>
                        </p>
                        <br />
                    </div>
                </div>
                <div className="article-full visible-box">
                    <div className="transcard text-center">
                        <div className="offset-icons-top">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-1.png" alt="" />
                                        </figure>
                                        <h3>24/7 WORLDWIDE DATA ANALYSIS</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-2.png" alt="" />
                                        </figure>
                                        <h3>FREEDOM AND SECURITY FOR YOUR CRYPTO ASSETS</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="dot-text no-square">
                                        <figure>
                                            <img src="/img/defi/icon-3.png" alt="" />
                                        </figure>
                                        <h3>TRADING WITH NO EMOTION AND FATIGUE</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lines-box text-center">
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <a className="link-btn" href="https://defibot.com/register/?ref=bonexx" target="_blank">START NOW</a>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <h2>Why artificial intelligence?</h2>
                        <h4><strong>Crypto market offers endless profit possibilities, but the sad truth is that over 80% of the users are losing, because of their emotions and lack of knowledge about market cycles and price movements. For this reason artificial intelligence (AI) is the most sustainable solution for you in the dynamic crypto sphere. Its movements are based only on facts and accurate calculations. Not only that, but our bot has the ability to analyze potential losing trades and adapt to the new market conditions. </strong></h4>
                        <h2>Your investment is in safe hands</h2>
                        <h4><strong>DeFiBot uses only low-risk strategies for trading and historically proved technical indicators, it doesn’t use leverage and 24/7 analyses huge amounts of information in real time. DeFiBot trades through an API code, which you have to generate. We don’t have any access to your funds, we don’t lock any crypto we manage - you can withdraw them any time you want. This is what makes DeFiBot a unique and reliable product.</strong></h4>
                        <br /><br />
                    </div>
                </div>
                <a href="https://defibot.com/register/?ref=bonexx" target="_blank">
                    <img src="/img/defi/Defibot_Offer_EN.png" alt="" />
                </a>
            </div>
        )
    }
}

export default DefiGR;
