import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import ErrorBar from './utilities/ErrorBar';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: '',
            translationsLoaded: false
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({ location: this.props.location });
        this.unlisten = this.props.history.listen((location, action) => {
            this.setState({ location: location });
        });
    }

    async componentDidMount() {
        // Mark translations as loaded when activeLanguage is set
        this.setState({ translationsLoaded: true });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    renderMenu() {
        if (!this.state.translationsLoaded) {
            return null; // Avoid hydration mismatches
        }

        if (this.props.auth.authenticated) {
            return (
                <nav className="sticky-footer-menu">
                    <ul>
                        <li className={this.state.location.pathname.indexOf('orders') != -1 ? 'active' : ''}>
                            <Link to="/orders" title="">
                                <div className="" style={{ width: "23px", height: "22px", textAlign: "center", margin: "0px auto 10px", fontSize: "16px" }}>
                                    <i className="far fa-credit-card"></i> {` `}
                                </div>
                                <Translate id="orders" />
                            </Link>
                        </li>
                        <li className={this.state.location.pathname.indexOf('transactions') != -1 ? 'active' : ''}>
                            <Link to="/transactions" title="">
                                <div className="" style={{ width: "23px", height: "22px", textAlign: "center", margin: "0px auto 10px", fontSize: "16px" }}>
                                    <i className="fas fa-exchange-alt"></i>
                                </div>
                                <Translate id="transactions" />
                            </Link>
                        </li>
                        <li className={this.state.location.pathname.indexOf('accounts') != -1 ? 'active' : ''}>
                            <Link to="/accounts" title="">
                                <div className="" style={{ width: "23px", height: "22px", textAlign: "center", margin: "0px auto 10px", fontSize: "16px" }}>
                                    <i className="far fa-wallet"></i>
                                </div>
                                <Translate id="balance" />
                            </Link>
                        </li>
                        <li className={this.state.location.pathname.indexOf('profile') != -1 ? 'active' : ''}>
                            <Link to="/profile" title="">
                                <div className="" style={{ width: "23px", height: "22px", textAlign: "center", margin: "0px auto 10px", fontSize: "16px" }}>
                                    <i className="far fa-user-circle"></i>
                                </div>
                                <Translate id="profile" />
                            </Link>
                        </li>
                        <li className={this.state.location.pathname.indexOf('settings') != -1 ? 'active' : ''}>
                            <Link to="/settings" title="">
                                <div className="" style={{ width: "23px", height: "22px", textAlign: "center", margin: "0px auto 10px", fontSize: "16px" }}>
                                    <i className="far fa-shield"></i>
                                </div>
                                <Translate id="settings" />
                            </Link>
                        </li>
                    </ul>
                </nav>
            )
        } else {
            return <span></span>;
        }
    }

    render() {
        return (
            <footer id="footer" style={{
                paddingBottom: '0px'
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                            <nav className="footer-menu">
                                <ul className="nav navbar-nav" id="main-menu">
                                    <li className=" nav-item">
                                        <Link to="/news" className="nav-link"><span className="title"><Translate id="news" /></span></Link>
                                    </li>
                                    <li className=" nav-item">
                                        <Link to="/about-us" className="nav-link"><span className="title"><Translate id="aboutUs" /></span></Link>
                                    </li>
                                    <li className=" nav-item">
                                        <Link to="/faq" className="nav-link"><span className="title"><Translate id="faq" /></span></Link>
                                    </li>
                                    <li className=" nav-item">
                                        <Link to="/privacy" className="nav-link"><span className="title"><Translate id="privacyPolicy" /></span></Link>
                                    </li>
                                    <li className=" nav-item">
                                        <Link to="/terms" className="nav-link"><span className="title"><Translate id="termsOfUse" /></span></Link>
                                    </li>
                                    <li className=" nav-item">
                                        <Link to="/cookies" className="nav-link"><span className="title"><Translate id="cookies" /></span></Link>
                                    </li>
                                    <li className=" nav-item">
                                        <Link to="/aml" className="nav-link"><span className="title"><Translate id="amlPolicy" /></span></Link>
                                    </li>
                                    <li className=" nav-item">
                                        <Link to="/contacts" className="nav-link"><span className="title"><Translate id="contacts" /></span></Link>
                                    </li>
                                </ul>
                            </nav>
                            {/* <p className="footer-copyright">Copyright © 2019-2023 Bonexx LTD. All Rights Reserved.</p> */}
                            <ul className="social-links">
                                <li>
                                    <a href="https://www.facebook.com/bonex.exchange" title="Bonex Exchange Facebook Page" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                {/* <li>
                                    <a href="https://twitter.com/BonexExchange" title="Bonex Exchange Twitter Profile" target="_blank"><i className="fab fa-twitter"></i></a>
                                </li> */}
                                <li>
                                    <a href="https://www.instagram.com/bonex.exchange" title="Bonex Exchange Instagram Profile" target="_blank"><i className="fab fa-instagram"></i></a>
                                </li>
                                {/* <li>
                                    <a href="https://t.me/+Hc9xy4CaQgk0NWE8" title="Bonex Exchange Telegram group" target="_blank"><i className="fab fa-telegram"></i></a>
                                </li> */}
                            </ul>
                            <div id="footer-logo" style={{
                                marginBottom: '0px'
                            }}>
                                <div className="text-center">
                                    <a
                                        href="https://bonex.group/"
                                        className="nav-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textDecoration: 'none'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#fff',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '10px'
                                            }}
                                        >
                                            PART OF
                                        </span>
                                        <img
                                            src="/img/logo-bonex-group.png"
                                            alt="Logo Bonex Group"
                                            title="BoneX Crypto Exchange"
                                            style={{ width: '160px' }}
                                        />
                                        <img
                                            className="footer-light-logo"
                                            src="/img/logo-footer-new-light.png"
                                            alt="Footer Light Logo"
                                            title="BoneX Crypto Exchange"
                                            style={{ marginLeft: '10px' }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderMenu()}
                <ErrorBar />
            </footer>

        );
    }
};

function mapStateToProps({ auth }) {
    return { auth };
}

export default connect(mapStateToProps, {})(withRouter(Footer));
