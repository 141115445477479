import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import { fetchAccounts, fetchRates, fetchPublicTransactions } from "../../actions";

import CardsBonexBG from "../../components/cards/CardsBonexBG";
import CardsBonexEN from "../../components/cards/CardsBonexEN";
import CardsBonexRO from "../../components/cards/CardsBonexRO";

class CardsBonexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.activeLanguage.code
    };
  }

  head() {
    return (
      <Helmet>
        <title>BoneX | Crypto Exchange</title>
        <meta
          property="og:title"
          content="BoneX | Crypto Exchange"
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    if (this.props.auth && this.props.auth.authenticated) {
      //this.props.fetchAccounts();
      //this.props.fetchRates();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeLanguage.code != this.state.lang) {
      this.setState({
        lang: this.props.activeLanguage.code,
      });
      // this.executeJQuery();
    }
  }

  renderPage() {
    const sharedProps = {
      //auth: this.props.auth,
      //account: this.props.account,
      //rates: this.props.rates,
      currency: this.props.currency,
      //accounts: this.props.accounts,
      user: this.props.user
    };

    if (this.state.lang === "bg") {
      return <CardsBonexBG {...sharedProps} />;
    } else if (this.state.lang === "en") {
      return <CardsBonexEN {...sharedProps} />;
    } else if (this.state.lang === "ro") {
      return <CardsBonexRO {...sharedProps} />;
    } else {
      return <CardsBonexEN {...sharedProps} />;
    }
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {this.head()}
              {this.renderPage()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {

    //auth: state.auth,
    user: state.user,
    //accounts: state.accounts,
    //account: state.account,
    //rates: state.rates,
    currency: state.currency
  };
}

function loadData({ dispatch, getState }) {
  const { auth } = getState();
  if (auth && auth.authenticated) {
    return Promise.all([
      //dispatch(fetchAccounts()),
      //dispatch(fetchRates())
    ]);
  }
  return Promise.resolve();
}

const WrappedComponent = connect(
  mapStateToProps,
  //{ fetchAccounts, fetchRates }
)(withLocalize(CardsBonexPage));

export default {
  component: WrappedComponent,
  loadData
};