// /Users/miroslav.katsarov/Documents/work/bonex.org/bonex-spot-client/src/client/reducers/userReducer.js

import {
    FETCH_USER_DATA,
    CLEAR_USER_DATA,
    UPDATE_GENERAL_SETTINGS,
    UPDATE_SECURITY_SETTINGS,
    SEND_CHARITY_TRANSACTION,
    SEND_CHARITY_TRANSACTION_SUCCESS,
    SEND_CHARITY_TRANSACTION_FAILURE
} from '../actions/types';

const initialState = {
    generalSettings: {},
    securitySettings: {},
    charityTransaction: {
        loading: false,
        success: false,
        error: null,
        data: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_DATA:
            return { 
                ...state, 
                ...action.payload 
            };
        case UPDATE_GENERAL_SETTINGS:
            return { 
                ...state, 
                generalSettings: { ...state.generalSettings, ...action.payload } 
            };
        case UPDATE_SECURITY_SETTINGS:
            return { 
                ...state, 
                securitySettings: { ...state.securitySettings, ...action.payload } 
            };
        case CLEAR_USER_DATA:
            return { 
                ...initialState 
            };
        case SEND_CHARITY_TRANSACTION:
            return {
                ...state,
                charityTransaction: {
                    loading: true,
                    success: false,
                    error: null,
                    data: null
                }
            };
        case SEND_CHARITY_TRANSACTION_SUCCESS:
            return {
                ...state,
                charityTransaction: {
                    loading: false,
                    success: true,
                    error: null,
                    data: action.payload
                },
                // Ако е необходимо, обнови и потребителските данни
                userData: { ...state.userData, ...action.payload.updatedUserData }
            };
        case SEND_CHARITY_TRANSACTION_FAILURE:
            return {
                ...state,
                charityTransaction: {
                    loading: false,
                    success: false,
                    error: action.payload,
                    data: null
                }
            };
        default:
            return state;
    }
};
